import { useState } from 'react';
import { Button } from 'portal-commons';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';

import AppealHistoryTable from '../AppealHistoryTable';
import AppealRequestModal from '../AppealRequestModal';
import AppealHistoryModal from '../AppealHistoryModal';
import AppealRequestConfirmationModal from '../AppealRequestConfirmationModal/AppealRequestConfirmationModal';
import AppealRequestWarningModal from '../AppealRequestWarningModal';
import BrandDetailItem from '../BrandDetailItem';
import BrandResubmissionPromptModal from '../BrandResubmissionPromptModal/BrandResubmissionPromptModal';
import { requestIdentityVettingAppeal } from '../../apiServices';
import { toastFlashMessage } from '../../../../../utils';
import { S3_ASSETS_PATH } from '../../../../../constants';
import { isValidForOperation } from '../../utils';
import {
  AppealCategoryData,
  AppealRequestData,
  BrandDetail,
  BrandFeedback,
  VettingAppeal,
} from '../../types';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faSquarePollHorizontal,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '21px 21px 22px 31px',
    border: '1px solid #C0CCD4',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '9px',
    paddingBottom: '17px',
    borderBottom: '1px solid #C0CCD4',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#49535D',
  },
  icon: {
    width: '24px',
    height: '24px',
    objectFit: 'cover',
  },
  title: {
    flex: '1',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '9px',
    marginTop: '33px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#49535D',
  },
  viewIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    '&:hover': {
      borderRadius: '50%',
      background: '#D1E4EC',
    },
    '&:hover svg': {
      color: '#0091B3',
    },
  },
  identityStatus: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  tooltip: {
    padding: '9px 5px 9px 11px',
    marginBottom: '11px',
    background: '#00797C',
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
    },
    '& li': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  arrow: {
    color: '#00797C',
  },
});

const getIdentityStatus = (brand: BrandDetail): string => {
  return brand && brand.identityStatus ? brand.identityStatus : 'PENDING';
};

const isDisabled = (brand: BrandDetail): boolean => {
  return brand && brand.identityStatus ? false : true;
};

const getRussell3000 = (brand: BrandDetail): string => {
  return brand &&
    brand.optionalAttributes &&
    brand.optionalAttributes.russell3000
    ? 'True'
    : 'False';
};

const getPoliticalCommitteeLocale = (brand: BrandDetail): string | null => {
  const politicalCommitteeLocale =
    brand.optionalAttributes?.politicalCommitteeLocale;
  return politicalCommitteeLocale
    ? `${politicalCommitteeLocale[0].toUpperCase()}${politicalCommitteeLocale.slice(
        1
      )}`
    : null;
};

const getTaxExemptStatus = (brand: BrandDetail): string => {
  if (brand && brand.optionalAttributes) {
    if (brand.optionalAttributes.section527) {
      return '527';
    }
    if (brand.optionalAttributes.taxExemptStatus) {
      return brand.optionalAttributes.taxExemptStatus;
    }
  }
  return 'N/A';
};

const getGovernmentEntity = (brand: BrandDetail): string => {
  return brand &&
    brand.optionalAttributes &&
    brand.optionalAttributes.governmentEntity
    ? 'True'
    : 'False';
};

interface Props {
  role: string;
  brand: BrandDetail;
  feedbackInfo: BrandFeedback[];
  appeals: VettingAppeal[];
  appealCategories: AppealCategoryData[];
  validAppealCategoryIds: string[];
  disabledAppealCategoryIds: string[];
  onAppealCreate: (_appeal: VettingAppeal) => void;
  onResubmitClick: () => void;
}

const BrandIdentityStatus: React.FC<Props> = ({
  role,
  brand,
  feedbackInfo,
  appeals,
  appealCategories,
  validAppealCategoryIds,
  disabledAppealCategoryIds,
  onAppealCreate,
  onResubmitClick,
}) => {
  const [appealRequestOpen, setAppealRequestOpen] = useState(false);
  const [appealRequestData, setAppealRequestData] =
    useState<AppealRequestData | null>(null);
  const [appealRequestConfirmationOpen, setAppealRequestConfirmationOpen] =
    useState(false);
  const [
    appealRequestConfirmationDisabled,
    setAppealRequestConfirmationDisabled,
  ] = useState(false);
  const [appealHistoryOpen, setAppealHistoryOpen] = useState(false);
  const [appealHistoryIndex, setAppealHistoryIndex] = useState(-1);
  const [brandResubmissionPromptOpen, setBrandResubmissionPromptOpen] =
    useState(false);
  const [appealRequestWarningOpen, setAppealRequestWarningOpen] =
    useState(false);
  const classes = useStyles();

  const handleAppealRequest = () => {
    if (
      brand.identityStatus.toLowerCase() === 'unverified' &&
      !brand.vettedDate
    ) {
      setBrandResubmissionPromptOpen(true);
    } else if (
      validAppealCategoryIds.length === disabledAppealCategoryIds.length
    ) {
      setAppealRequestWarningOpen(true);
    } else {
      setAppealRequestOpen(true);
    }
  };

  const handleAppealView = async (index: number) => {
    setAppealHistoryOpen(true);
    setAppealHistoryIndex(index);
  };

  return (
    <Grid
      style={{ marginBottom: 34 }}
      container
      data-testid="brandIdentityStatus"
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Box flexDirection="row" alignItems="center" width="100%">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon className={classes.icon} icon={faUserCircle} />
            </Box>
            <span className={classes.title}>Identity Status</span>
          </Box>
          <div className={classes.actions}>
            {brand && validAppealCategoryIds.length > 0 && (
              <Button
                portal="csp"
                shape="square"
                disabled={!isValidForOperation(role) || isDisabled(brand)}
                onClick={handleAppealRequest}
                data-testid="brandIdentityStatusRequestAppealButton"
              >
                Identity Status Appeal
              </Button>
            )}
            <Button
              portal="csp"
              shape="square"
              variant="outline"
              disabled={!isValidForOperation(role) || isDisabled(brand)}
              onClick={onResubmitClick}
              data-testid="brandIdentityStatusResubmitBrandButton"
            >
              Resubmit Brand
            </Button>
          </div>
        </div>

        <Grid style={{ marginTop: 24 }} container>
          <Grid item xs={6}>
            <Grid container spacing={3} className="detail-item">
              <Grid item xs={5}>
                <h3 className="paragraph">Identity Status:</h3>
              </Grid>
              <Grid
                style={{
                  position: 'relative',
                }}
                item
                xs={7}
              >
                <div className={classes.identityStatus}>
                  <h3 className="paragraph">
                    <span>{getIdentityStatus(brand) || 'N/A'}</span>
                  </h3>
                  {feedbackInfo && feedbackInfo.length > 0 && (
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.arrow,
                      }}
                      placement={'top-end'}
                      arrow
                      title={
                        <ul>
                          {feedbackInfo.map((item) => (
                            <li key={item.id}>
                              {item.id} - {item.description}
                            </li>
                          ))}
                        </ul>
                      }
                    >
                      <span
                        className={classes.viewIcon}
                        data-testid="feedbackInfo"
                      >
                        <Box
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Box>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            </Grid>
            <BrandDetailItem
              xs={5}
              title="Russell3000"
              value={getRussell3000(brand)}
            />
            <BrandDetailItem
              xs={5}
              title="Political Committee Locale"
              value={getPoliticalCommitteeLocale(brand)}
            />
          </Grid>
          <Grid item xs={6}>
            <BrandDetailItem
              xs={5}
              title="Tax Exempt Status"
              value={getTaxExemptStatus(brand)}
            />
            <BrandDetailItem
              xs={5}
              title="Government Entity"
              value={getGovernmentEntity(brand)}
            />
          </Grid>
        </Grid>

        <div className={classes.subheader}>
          <Box flexDirection="row" alignItems="center" width="100%">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon
                className={classes.icon}
                icon={faSquarePollHorizontal}
              />
            </Box>
            <span className={classes.title}>Appeal History</span>
          </Box>
        </div>

        <AppealHistoryTable
          style={{ marginTop: 13 }}
          appeals={appeals}
          onAppealView={handleAppealView}
        />

        <AppealRequestModal
          open={appealRequestOpen}
          brand={brand}
          title="Identity Status Appeal Request"
          message="You can submit an Appeal up to 45 days after the submission of a Brand. Appeals are charged $10 regardless of the outcome. Do not use this to appeal a Standard Vet."
          categories={appealCategories.filter((category) =>
            validAppealCategoryIds.includes(category.appealCategoryId)
          )}
          disabledCategoryIds={disabledAppealCategoryIds}
          onClose={() => setAppealRequestOpen(false)}
          onSubmit={(data: AppealRequestData) => {
            setAppealRequestOpen(false);
            setAppealRequestData(data);
            setAppealRequestConfirmationOpen(true);
          }}
        />
        <AppealRequestConfirmationModal
          open={appealRequestConfirmationOpen}
          disabled={appealRequestConfirmationDisabled}
          onClose={() => {
            setAppealRequestData(null);
            setAppealRequestConfirmationOpen(false);
          }}
          onSubmit={async () => {
            if (!appealRequestData) return;
            setAppealRequestConfirmationDisabled(true);
            try {
              const appeal: VettingAppeal = await requestIdentityVettingAppeal(
                brand.uid,
                appealRequestData.categories,
                appealRequestData.explanation,
                appealRequestData.attachments
              );
              toastFlashMessage('Vetting Appeal Requested', 'success');
              onAppealCreate && onAppealCreate(appeal);
            } catch (e) {
              // Ignore
            }
            setAppealRequestData(null);
            setAppealRequestConfirmationOpen(false);
            setAppealRequestConfirmationDisabled(false);
          }}
        />
        <AppealHistoryModal
          open={appealHistoryOpen}
          title="Identity Status"
          appeal={appeals[appealHistoryIndex]}
          appealCategories={appealCategories}
          onClose={() => setAppealHistoryOpen(false)}
        />
        <BrandResubmissionPromptModal
          open={brandResubmissionPromptOpen}
          onClose={() => setBrandResubmissionPromptOpen(false)}
        />
        <AppealRequestWarningModal
          open={appealRequestWarningOpen}
          appealType="Identity Status"
          entityType={brand.entityType}
          identityStatus={brand.identityStatus}
          onClose={() => setAppealRequestWarningOpen(false)}
        />
      </div>
    </Grid>
  );
};

export default BrandIdentityStatus;
