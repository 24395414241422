export const MAX_FILE_SIZE = 10485760;
export const MAX_SUPPORTING_DOCUMENT_COUNT = 5;
export const MAX_SAMPLE_MULTIMEDIA_COUNT = 5;

export const SUPPORTED_FILE_EXTENSIONS_JSON = {
  '.bmp': 'image/bmp',
  '.css': 'text/css',
  '.mpeg': 'video/mpeg',
  '.oga': 'audio/ogg',
  '.csv': 'text/csv',
  '.pdf': 'application/pdf',
  '.mp3': 'audio/mpeg',
  '.txt': 'text/plain',
  '.flv': 'video/x-flv',
  '.smil': 'application/smil',
  '.mp4': 'video/mp4',
  '.mp1': 'audio/mpeg',
  '.mp2': 'audio/mpeg',
  '.m4v': 'video/mp4',
  '.m1a': 'audio/mpeg',
  '.dib': 'image/bmp',
  '.tiff': 'image/tiff',
  '.mpg': 'video/mpeg',
  '.tar': 'application/x-tar',
  '.gz': 'application/x-gzip',
  '.ogx': 'video/ogg',
  '.zip': 'application/zip',
  '.ogv': 'video/ogg',
  '.mpa': 'audio/mpeg',
  '.json': 'application/json',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.flac': 'audio/flac',
  '.mov': 'video/quicktime',
  '.pjpeg': 'image/pjpeg',
  '.ico': 'image/x-icon',
  '.ogm': 'video/ogg',
  '.m1v': 'video/mpeg',
  '.vcard': 'text/vcard',
  '.tif': 'image/tiff',
  '.ogg': 'video/ogg',
  '.m2a': 'audio/mpeg',
  '.xml': 'text/xml',
  '.gif': 'image/gif',
  '.3ga': 'audio/amr',
  '.amr': 'audio/amr',
  '.qt': 'video/quicktime',
  '.mpv': 'video/mpeg',
  '.spx': 'video/ogg',
  '.rtf': 'application/rtf',
  '.svg': 'image/svg+xml',
  '.wmv': 'video/x-ms-wmv',
  '.jpg': 'image/jpeg',
  '.wav': 'audio/wav',
  '.m4r': 'audio/mp4',
  '.m4p': 'audio/mp4',
  '.wap': 'text/vnd.wap.wml',
  '.avi': 'video/avi',
  '.webp': 'image/webp',
  '.cal': 'text/calendar',
  '.js': 'text/javascript',
  '.webm': 'video/webm',
  '.bz2': 'application/x-bzip2',
  '.m4a': 'audio/mp4',
  '.m4b': 'audio/mp4',
  '.3gp': 'audio/3gpp',
  '.vcf': 'text/vcard',
};

export const ATTACHMENT_MIME_TYPES = Object.values(
  SUPPORTED_FILE_EXTENSIONS_JSON
);

export const ATTACHMENT_FOLDER_MMS = 'MMS_SAMPLE_MEDIA';

export const SUPPORTED_FILE_EXTENSIONS = Object.keys(
  SUPPORTED_FILE_EXTENSIONS_JSON
);

export const SUPPORTED_FILE_TYPES_TOOLTIP = `Supported file types: ${SUPPORTED_FILE_EXTENSIONS.sort().join(
  ', '
)}`;

export const NUDGE_TYPES = {
  REVIEW: 'REVIEW',
  APPEAL_REJECTION: 'APPEAL_REJECTION',
};

export const MIGRATION_STATUS_LABEL_MAP = {
  ACTIVE: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'No',
};
