import { Button, Table, TableFilter, withFilters } from 'portal-commons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, withStyles } from '@material-ui/core';

import { Header } from '../../../../shared_elements';
import {
  PartnerCampaignListingRow,
  ElectCNPDialog,
  RejectCampaignDialog,
} from '../components';
import Loader from '../../../../shared_elements/containers/Loader';
import {
  getPartnerCampaignsListApi,
  getCnpListApi,
  findAllUsecaseTypes,
  downloadCnpCampaignsAsCsv,
  findAllCnps,
  getSPCnpListApi,
} from '../apiServices';
import queryString from 'query-string';
import '../../../../assets/styles/partner-campaigns-listing-module.scss';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faArrowDownToLine,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';

const normalFilterConfigs = {
  campaignUid: {
    type: 'text',
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  usecase: {
    type: 'dropdown',
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  brandUid: {
    type: 'text',
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  downstreamCnpUid: {
    type: 'dropdown',
    label: 'Downstream CNP',
    placeholder: 'Select downstream CNP',
    options: [],
    width: 260,
  },
  status: {
    type: 'dropdown',
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    width: 230,
  },
  outSharingStatus: {
    type: 'dropdown',
    label: 'Sharing Status',
    placeholder: 'Select sharing status',
    options: [
      { label: 'Pending', value: 'PENDING' },
      { label: 'Rejected', value: 'DECLINED' },
    ],
    width: 230,
  },
  provisional: {
    type: 'dropdown',
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const historyFilterConfigs = {
  campaignUid: {
    type: 'text',
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  brandUid: {
    type: 'text',
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  usecase: {
    type: 'dropdown',
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  downstreamCnpUid: {
    type: 'dropdown',
    label: 'Downstream CNP',
    placeholder: 'Select downstream CNP',
    options: [],
    width: 260,
  },
  upstreamCnpUid: {
    type: 'dropdown',
    label: 'Upstream CNP',
    placeholder: 'Select upstream CNP',
    options: [],
    width: 260,
  },
  status: {
    type: 'dropdown',
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Deactivated',
        value: 'EXPIRED',
      },
    ],
    width: 230,
  },
  provisional: {
    type: 'dropdown',
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: false },
  { id: 'downstreamCnpName', label: 'DOWNSTREAM CNP', sortable: false },
  { id: 'registeredOnDate', label: 'REGISTERED ON', sortable: false },
  { id: 'sharedOnDate', label: 'SHARED WITH ME ON', sortable: true },
  { id: '', label: 'CARRIER TERMS', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  { id: '', label: 'SHARING STATUS', sortable: false },
];

const historyHeadRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: false },
  { id: 'downstreamCnpName', label: 'DOWNSTREAM CNP', sortable: false },
  { id: 'sharedOnDate', label: 'SHARED WITH ME ON', sortable: true },
  { id: 'upstreamCnpName', label: 'UPSTREAM CNP', sortable: false },
  { id: 'sharedToDate', label: 'SHARED TO ON', sortable: false },
  { id: '', label: 'CARRIER TERMS', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: false },
];

const styles = {
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
  spacing: {
    flex: 1,
  },
  icon: {
    width: '16px',
    height: '16px',
    objectFit: 'contain',
  },
};

class PartnerCampaigns extends Component {
  constructor(props) {
    super(props);

    const { pathname } = props.location;
    this.state = {
      loader: true,
      partnerCampaignsInfo: {},
      historyPage: false,
      tableLoader: true,
      electCnpFlag: false,
      rejectCnpFlag: false,
      cnpOptions: [],
      SPCnpOptions: [],
      campaignUid: '',
      brandId: '',
      downstreamCnpName: '',
      usecase: '',
      filtersShown: false,
      additionalFilters: {},
    };
    this.getPartnerCampaignsListApi = getPartnerCampaignsListApi.bind(this);
    this.getCnpListApi = getCnpListApi.bind(this);
    this.getSPCnpListApi = getSPCnpListApi.bind(this);
  }

  replaceUrlIfMissRequiredFilter = () => {
    const { search, pathname } = this.props.location;
    const queries = queryString.parse(search, { decode: true });

    if (!['true', 'false'].includes(queries.provisional)) {
      queries.provisional = 'false';
      this.props.history.replace({
        pathname: pathname,
        search: queryString.stringify(queries, { encode: false }),
      });
      return true;
    }

    return false;
  };

  componentDidMount() {
    const { pathname, search } = this.props.location;
    const { updateConfigObject } = this.props.filter;
    const isHistoryMode = pathname !== '/cnp-campaigns';
    if (isHistoryMode) {
      updateConfigObject(historyFilterConfigs);
    } else {
      updateConfigObject(normalFilterConfigs);
    }
    const currentQueries = queryString.parse(search, {
      decode: true,
    });
    this.updateElectedCnpList(currentQueries?.provisional);
    findAllUsecaseTypes().then((response) => {
      this.generateFilterOptions('usecase', response.data);
    });
    findAllCnps().then((response) => {
      this.generateFilterOptions('downstreamCnpUid', response);
      if (isHistoryMode) {
        this.generateFilterOptions('upstreamCnpUid', response);
      }
    });

    if (this.replaceUrlIfMissRequiredFilter()) {
      return;
    }
    this.updateFiltersAndFetchCampaigns();
  }

  componentDidUpdate(prevProps) {
    if (this.replaceUrlIfMissRequiredFilter()) {
      return;
    }
    const currentSearch = this.props.location.search;
    const prevSearch = prevProps.location.search;

    if (prevSearch !== currentSearch) {
      this.updateFiltersAndFetchCampaigns();
    }

    const currentQueries = queryString.parse(currentSearch, {
      decode: true,
    });

    const prevQueries = queryString.parse(prevSearch, {
      decode: true,
    });

    if (prevQueries?.provisional !== currentQueries?.provisional) {
      this.updateElectedCnpList(currentQueries?.provisional);
    }
  }

  updateElectedCnpList = (provisionalQueryParam) => {
    const isHistoryMode = this.props.location.pathname !== '/cnp-campaigns';
    if (isHistoryMode) return;
    const cnpMigrationSupported =
      provisionalQueryParam === 'true' ? true : undefined;
    this.getCnpListApi({
      cnpMigrationSupported,
    });
    this.getSPCnpListApi({
      cnpMigrationSupported,
    });
  };

  generateFilterOptions = (key, data) => {
    const options = data.map((item) => ({
      label: item.displayName,
      value: item.uid ? item.uid : item.id,
    }));
    const { setOptions } = this.props.filter;
    setOptions(key, options);
  };

  updateFiltersAndFetchCampaigns = () => {
    const { pathname, search } = this.props.location;
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    const isNormalMode = pathname === '/cnp-campaigns';
    const queries = queryString.parse(search, {
      decode: true,
    });

    const queryEntries = Object.entries(queries);
    if (queryEntries.length > 0) {
      const { configs } = this.props.filter;
      const newAdditionalFilters = {
        sortField: 'sharedOnDate',
        ascendingOrder: false,
        ...additionalFilters,
      };
      queryEntries.forEach(([key, value]) => {
        if (!configs[key] && key !== 'page') {
          newAdditionalFilters[key] =
            key === 'ascendingOrder' || key === 'actionableOnly'
              ? JSON.parse(value)
              : value;
        }
      });
      if (isNormalMode) {
        newAdditionalFilters.sharingStatus = '';
        newAdditionalFilters.actionableOnly = true;
      } else {
        newAdditionalFilters.sharingStatus = 'ACCEPTED';
        newAdditionalFilters.outSharingStatus = 'PENDING,ACCEPTED';
        newAdditionalFilters.actionableOnly = false;
      }
      this.setState({
        historyPage: !isNormalMode,
        additionalFilters: newAdditionalFilters,
      });
      this.getPartnerCampaignsListApi({
        provisional: false,
        ...appliedFilters,
        ...newAdditionalFilters,
        page: queries.page ? queries.page : 1,
      });
    } else {
      const newAdditionalFilters = {
        sortField: 'sharedOnDate',
        ascendingOrder: false,
        sharingStatus: isNormalMode ? '' : 'ACCEPTED',
        outSharingStatus: isNormalMode ? '' : 'PENDING,ACCEPTED',
        actionableOnly: isNormalMode,
        provisional: false,
      };
      this.setState({
        historyPage: !isNormalMode,
        additionalFilters: newAdditionalFilters,
      });
      this.getPartnerCampaignsListApi({
        ...newAdditionalFilters,
      });
    }
  };

  handleCloseCnpDialog = (successFlag = false) => {
    this.setState({ electCnpFlag: false, rejectCnpFlag: false });
    if (successFlag) {
      this.updateFiltersAndFetchCampaigns();
    }
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilters, ...additionalFilters, page: newPage },
        { encode: false }
      )}`,
    });
  };

  createSortHandler = (sortField) => {
    const { appliedFilters } = this.props.filter;
    const { partnerCampaignsInfo, additionalFilters } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (partnerCampaignsInfo.totalRecords) {
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            ...appliedFilters,
            ...additionalFilters,
            page: partnerCampaignsInfo.page,
            sortField,
            ascendingOrder: !additionalFilters.ascendingOrder,
          },
          { encode: false }
        )}`,
      });
    }
  };

  handleClickAction = (type, campaignUid, campaignData = '', usecase = '') => {
    if (type === 'electCnp') {
      this.setState({
        electCnpFlag: true,
        campaignUid,
        usecase,
      });
    } else {
      this.setState({
        rejectCnpFlag: true,
        campaignUid,
        brandId: campaignData.brandUid,
        downstreamCnpName: campaignData.downstreamCnpName,
        usecase,
      });
    }
  };

  handleTableFilterToggle = () => {
    const { filtersShown } = this.state;
    this.setState({
      filtersShown: !filtersShown,
    });
  };

  handleDownload = async () => {
    const { appliedFilters } = this.props.filter;
    try {
      this.props.setLoadingMessage('We are generating the document');
      const response = await downloadCnpCampaignsAsCsv({
        ...appliedFilters,
        ...this.state.additionalFilters,
      });
      const file = new File([response], 'cnp-campaigns.csv');
      const url = URL.createObjectURL(file);
      const element = document.createElement('a');
      element.href = url;
      element.download = file.name;
      document.body.appendChild(element);
      element.click();
    } finally {
      this.props.clearLoadingMessage();
    }
  };

  render() {
    const { classes } = this.props;
    const { pathname } = this.props.location;
    const {
      loader,
      historyPage,
      tableLoader,
      partnerCampaignsInfo,
      electCnpFlag,
      rejectCnpFlag,
      cnpOptions,
      campaignUid,
      brandId,
      downstreamCnpName,
      usecase,
      SPCnpOptions,
      filtersShown,
      additionalFilters,
    } = this.state;
    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleEdit: handleCandidateFiltersChange,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;
    return (
      <>
        <section className="partner-campaigns-listing-section">
          {loader ? (
            <Loader />
          ) : (
            <Container
              maxWidth={false}
              className="partner-campaigns-listing-container"
            >
              <div className={classes.title}>
                {!loader && partnerCampaignsInfo.totalRecords && !historyPage
                  ? `${partnerCampaignsInfo.totalRecords} Pending Connectivity Partner Campaigns `
                  : historyPage
                  ? 'Accepted Connectivity Partner Campaigns'
                  : 'Pending Connectivity Partner Campaigns'}
              </div>
              <div className={classes.buttons}>
                <Button
                  color="secondary"
                  onClick={this.handleTableFilterToggle}
                  data-testid="tableAddFilterButton"
                >
                  <FontAwesomeIcon icon={faFilter} />
                  <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
                </Button>
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={this.handleDownload}
                  data-testid="tableDownloadButton"
                >
                  <FontAwesomeIcon icon={faArrowDownToLine} />
                  Download
                </Button>
                <div className={classes.spacing} />
                {pathname === '/cnp-campaigns' && (
                  <Button
                    data-testid="showHistoryButton"
                    onClick={() =>
                      this.props.history.push(
                        '/cnp-campaigns/history?status=ACTIVE&provisional=false'
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faRotateRight} />
                    Show History
                  </Button>
                )}
              </div>
              {filtersShown && (
                <Grid container style={{ marginBottom: 12 }}>
                  <TableFilter
                    configs={filterConfigs}
                    candidateValues={candidateFilters}
                    appliedValues={appliedFilters}
                    onCandidateValuesChange={handleCandidateFiltersChange}
                    onAppliedValuesChange={handleAppliedFiltersChange}
                  />
                </Grid>
              )}
              <Grid container>
                <Table
                  className="partner-campaigns-listing-table"
                  loading={tableLoader}
                  emptyState="no campaigns to view"
                  records={{ total: partnerCampaignsInfo.totalRecords }}
                  handleChangePage={this.handleChangePage}
                  createSortHandler={this.createSortHandler}
                  filter={additionalFilters}
                  headRows={historyPage ? historyHeadRows : headRows}
                  tableData={partnerCampaignsInfo.records.map((record) => (
                    <PartnerCampaignListingRow
                      data={record}
                      key={record.uid}
                      type={historyPage ? 'history' : ''}
                      handleClickAction={this.handleClickAction}
                      {...this.props}
                    />
                  ))}
                  pagination={{
                    count: Math.ceil(
                      partnerCampaignsInfo.totalRecords /
                        partnerCampaignsInfo.recordsPerPage
                    ),
                    page: partnerCampaignsInfo.page,
                    rowsPerPage: partnerCampaignsInfo.recordsPerPage,
                    totalRecords: partnerCampaignsInfo.totalRecords,
                  }}
                />
              </Grid>
            </Container>
          )}
        </section>
        {
          <ElectCNPDialog
            open={electCnpFlag}
            cnpOptions={
              usecase === 'SOLE_PROPRIETOR' ? SPCnpOptions : cnpOptions
            }
            handleClose={this.handleCloseCnpDialog}
            campaignUid={campaignUid}
            usecase={usecase}
          />
        }
        {
          <RejectCampaignDialog
            open={rejectCnpFlag}
            handleClose={this.handleCloseCnpDialog}
            campaignUid={campaignUid}
            brandId={brandId}
            downstreamCnpName={downstreamCnpName}
          />
        }
      </>
    );
  }
}

PartnerCampaigns.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLoadingMessage: PropTypes.func,
  clearLoadingMessage: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoadingMessage: (message) =>
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    }),
  clearLoadingMessage: () =>
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    withFilters(PartnerCampaigns, {
      configs: normalFilterConfigs,
    })
  )
);
