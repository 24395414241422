import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { ENTITY_TYPES } from '../../../../constants';
import BrandSuspendedStatus from './BrandSuspendedStatus/BrandSuspendedStatus';
import { getMnoSuspensionStatus } from '../apiServices';
import { convertTimeWithTimezone } from '../../../../utils/time';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  cell: {
    paddingTop: '12px !important',
    verticalAlign: 'top !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconCell: {
    paddingTop: '7px !important',
    verticalAlign: 'top !important',
  },
  carriers: {
    position: 'absolute',
    top: '38px',
    right: 0,
    left: 0,
    bottom: 0,
    padding: '13px 0',
    margin: '0 16px',
    borderTop: '1px solid #C0CCD4',
    boxSizing: 'border-box',
    overflow: 'hidden',
    opacity: 0,
    transition: 'opacity 0s',
    display: 'none',
    '&.expanded': {
      display: 'block',
      opacity: 1,
      transition: 'opacity .3s cubic-bezier(1, 0, 1, 0)',
    },
  },
  more: {
    '&:hover, &.toggled': {
      color: '#D41C54',
    },
  },
});

function BrandListingRow(props) {
  const { data, mnos } = props;
  const [expanded, setExpanded] = useState(false);
  const [suspensionStatus, setSuspensionStatus] = useState({});
  const [suspensionResults, setSuspensionResults] = useState([]);
  const classes = useStyles();

  const handleMoreClick = async (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
    if (!Object.keys(suspensionStatus).length) {
      const newSuspensionStatus = {};
      try {
        const records = await getMnoSuspensionStatus(data.uid);
        for (const record of records) {
          newSuspensionStatus[record.mnoId] = record.suspended;
        }
        setSuspensionStatus(newSuspensionStatus);
      } catch (e) {}
    }
  };

  useEffect(() => {
    if (mnos && mnos.length > 0) {
      const suspensionResults = mnos.map((mno) => ({
        networkId: mno.networkId,
        displayName: mno.displayName,
        suspended: suspensionStatus[mno.networkId],
      }));
      setSuspensionResults(suspensionResults);
    }
  }, [mnos, suspensionStatus]);

  return (
    <TableRow
      className={`${classes.container} link`}
      style={{ height: expanded ? 104 : 40 }}
      data-testid="brandListingRow"
      onClick={() =>
        props.history.push({
          pathname: `/brands/${data.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        classes={{ root: classes.cell }}
        style={{ maxWidth: 300 }}
        data-testid="brandListingRowDisplayName"
      >
        <Tooltip title={data.displayName}>
          <span>{data.displayName}</span>
        </Tooltip>
      </TableCell>
      <TableCell
        align="left"
        classes={{ root: classes.cell }}
        style={{ maxWidth: 80 }}
        data-testid="brandListingRowUid"
      >
        {data.uid}
      </TableCell>
      <TableCell
        align="left"
        classes={{ root: classes.cell }}
        data-testid="brandListingRowCreatedDate"
      >
        {convertTimeWithTimezone(data.createDate, undefined, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell
        align="left"
        classes={{ root: classes.cell }}
        style={{ maxWidth: 140 }}
        data-testid="brandListingRowEntityType"
      >
        {data.entityType
          ? (ENTITY_TYPES.find((entity) => entity.value == data.entityType) &&
              ENTITY_TYPES.find((entity) => entity.value == data.entityType)
                .label) ||
            data.entityType
          : '--'}
      </TableCell>
      <TableCell
        align="left"
        classes={{ root: classes.cell }}
        style={{ maxWidth: 130 }}
        data-testid="brandListingRowCountry"
      >
        {data.country}
      </TableCell>
      <TableCell
        align="left"
        classes={{ root: classes.cell }}
        style={{ maxWidth: 130 }}
        data-testid="brandListingRowIdentityStatus"
      >
        {data.identityStatus ? data.identityStatus : 'N/A'}
      </TableCell>
      <TableCell
        classes={{ root: classes.iconCell }}
        data-testid="brandListingRowActionsMenu"
      >
        <MoreHorizIcon
          className={expanded ? 'toggled' : ''}
          classes={{ root: classes.more }}
          onClick={handleMoreClick}
        />
        <div className={`${classes.carriers} ${expanded ? 'expanded' : ''}`}>
          <BrandSuspendedStatus mnos={suspensionResults} />
        </div>
      </TableCell>
    </TableRow>
  );
}
export default withRouter(BrandListingRow);
