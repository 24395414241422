import moment from 'moment';
import { Button, Table, TableFilter } from 'portal-commons';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Container, withStyles } from '@material-ui/core';

import {
  getEventHistory,
  getAllEventTypes,
  downloadEventHistoryAsCsv,
} from '../apiServices';
import { globalGetService } from '../../../../utils/globalApiServices';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import Loader from '../../../../shared_elements/containers/Loader';
import EventsListingRow from '../components/EventsListingRow';
import '../../../../assets/styles/events-listing-module.scss';
import { MAX_EVENT_RECORDS } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';

const dateRangePresets = [
  {
    label: 'Today',
    start: new Date(),
    end: new Date(),
  },
  {
    label: 'Last 7 Days',
    start: moment().subtract(7, 'days').toDate(),
    end: new Date(),
  },
  {
    label: 'Last 1 Month',
    start: moment().subtract(1, 'months').toDate(),
    end: new Date(),
  },
  {
    label: 'Last 6 Months',
    start: moment().subtract(6, 'months').toDate(),
    end: new Date(),
  },
];

const defaultFilterConfigs = {
  campaignUid: {
    type: 'text',
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  brandUid: {
    type: 'text',
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  brandName: {
    type: 'text',
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 260,
  },
  eventType: {
    type: 'dropdown',
    label: 'Event Type',
    placeholder: 'Select event type',
    options: [],
    width: 230,
  },
  dateRange: {
    type: 'dateRange',
    label: 'Date Range',
    placeholder: 'Select date range',
    dateRangePresets,
  },
};

const headRows = [
  { label: 'EVENT', id: 'eventType', sortable: true },
  { label: 'CATEGORY', id: 'categoryType', sortable: true },
  { label: 'CAMPAIGN ID', id: 'campaignUid', sortable: true },
  { label: 'BRAND ID', id: 'brandUid', sortable: true },
  { label: 'DATE & TIME', id: 'createDate', sortable: true },
  { label: 'DESCRIPTION', id: 'description' },
];

const styles = {
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  note: {
    marginTop: 5,
    marginLeft: '12px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666E71',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '12px',
  },
};

class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      eventInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      eventTypes: [],
      filtersShown: false,
      filterConfigs: defaultFilterConfigs,
      candidateFilters: {},
      appliedFilters: {},
      additionalFilters: {},
    };
    this.getEventHistory = getEventHistory.bind(this);
    this.getAllEventTypes = getAllEventTypes.bind(this);
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Events';
    this.getAllEventTypes();
    this.updateFiltersAndFetchEvents();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateFiltersAndFetchEvents(prevProps.location.search);
    }
  }

  updateFiltersAndFetchEvents = (previousSearch = '') => {
    const { search } = this.props.location;
    const { candidateFilters, appliedFilters, additionalFilters } = this.state;
    const queries = queryString.parse(search, {
      decode: true,
    });
    const previousQueries = queryString.parse(previousSearch, {
      decode: true,
    });
    const queryEntries = Object.entries(queries);
    if (queryEntries.length > 0) {
      const newCandidateFilters = { ...candidateFilters };
      Object.keys(newCandidateFilters).forEach((key) => {
        if (queries[key] === undefined && previousQueries[key] !== undefined) {
          delete newCandidateFilters[key];
        }
      });
      const newAppliedFilters = { ...appliedFilters };
      Object.keys(defaultFilterConfigs).forEach((key) => {
        if (queries[key]) {
          if (!previousSearch) {
            newCandidateFilters[key] = queries[key];
          }
          newAppliedFilters[key] = queries[key];
        } else {
          delete newAppliedFilters[key];
        }
      });
      const newAdditionalFilters = {
        sortField: 'createDate',
        ascendingOrder: false,
        ...additionalFilters,
      };
      queryEntries.forEach(([key, value]) => {
        if (!defaultFilterConfigs[key] && key !== 'page') {
          newAdditionalFilters[key] =
            key === 'ascendingOrder' ? JSON.parse(value) : value;
        }
      });
      this.setState({
        candidateFilters: newCandidateFilters,
        appliedFilters: newAppliedFilters,
        additionalFilters: newAdditionalFilters,
      });
      this.getEventHistory({
        ...newAppliedFilters,
        ...newAdditionalFilters,
        page: queries.page ? queries.page : 1,
      });
    } else {
      const newAdditionalFilters = {
        sortField: 'createDate',
        ascendingOrder: false,
      };
      this.setState({
        candidateFilters: {},
        appliedFilters: {},
        additionalFilters: newAdditionalFilters,
      });
      this.getEventHistory({
        ...newAdditionalFilters,
      });
    }
  };

  fetchBrandSuggestions = (query = {}) => {
    globalGetService('csp/brands/suggestions', { ...query, limit: 20 }).then(
      (response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState((prevState) => ({
            filterConfigs: {
              ...prevState.filterConfigs,
              brandName: {
                ...prevState.filterConfigs.brandName,
                suggestions: response.data.map(
                  (suggestion) => suggestion.brandName
                ),
              },
            },
          }));
        }
      }
    );
  };

  handleChangePage = (newPage) => {
    const { appliedFilters, additionalFilters } = this.state;
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilters, ...additionalFilters, page: newPage },
        { encode: false }
      )}`,
    });
  };

  createSortHandler = (sortField) => {
    const { appliedFilters, eventInfo, additionalFilters } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (eventInfo.totalRecords) {
      // this.getEventHistory({...appliedFilters, page: eventInfo.page, sortField, ascendingOrder: !filter.ascendingOrder });
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            ...appliedFilters,
            ...additionalFilters,
            page: eventInfo.page,
            sortField,
            ascendingOrder: !additionalFilters.ascendingOrder,
          },
          { encode: false }
        )}`,
      });
    }
  };

  handleTableFilterToggle = () => {
    const { filtersShown } = this.state;
    this.setState({
      filtersShown: !filtersShown,
    });
  };

  handleCandidateFiltersChange = (values) => {
    const { filterConfigs, candidateFilters } = this.state;
    this.setState({
      candidateFilters: values,
    });

    if (values.brandName !== candidateFilters.brandName) {
      if (values.brandName && values.brandName.length > 1) {
        this.fetchBrandSuggestions({ prefix: values.brandName });
      } else {
        this.setState({
          filterConfigs: {
            ...filterConfigs,
            brandName: {
              ...filterConfigs.brandName,
              suggestions: [],
            },
          },
        });
      }
    }
  };

  handleAppliedFiltersChange = (values) => {
    this.setState({
      appliedFilters: values,
    });

    this.props.history.push({
      search: `?${queryString.stringify(
        { ...values, ...this.state.additionalFilters },
        { encode: false }
      )}`,
    });
  };

  handleDownload = async () => {
    try {
      this.props.setLoadingMessage('We are generating the document');
      const response = await downloadEventHistoryAsCsv({
        ...this.state.additionalFilters,
        ...this.state.appliedFilters,
      });
      const file = new File([response], 'event-history.csv');
      const url = URL.createObjectURL(file);
      const element = document.createElement('a');
      element.href = url;
      element.download = file.name;
      document.body.appendChild(element);
      element.click();
      this.props.clearLoadingMessage();
    } catch (e) {
      // ignore error
    }
  };

  render() {
    const { classes } = this.props;
    const {
      eventInfo,
      tableLoader,
      loader,
      filtersShown,
      filterConfigs,
      candidateFilters,
      appliedFilters,
      additionalFilters,
    } = this.state;

    const maskedTotalRecords =
      eventInfo.totalRecords > MAX_EVENT_RECORDS
        ? MAX_EVENT_RECORDS
        : eventInfo.totalRecords;
    const maskedPaginationData = {
      count: Math.ceil(maskedTotalRecords / eventInfo.recordsPerPage),
      rowsPerPage: eventInfo.recordsPerPage,
      page: eventInfo.page,
      totalRecords: maskedTotalRecords,
    };

    let overLimitDisclaimer;
    if (eventInfo.totalRecords > MAX_EVENT_RECORDS) {
      overLimitDisclaimer = (
        <div className="text-center" style={{ paddingTop: '25px' }}>
          This page can only show the latest {MAX_EVENT_RECORDS} records out of
          the total of {eventInfo.totalRecords}. Contact support for details on
          your remaining records.
        </div>
      );
    }
    return (
      <section
        className="events-listing-section"
        style={{ padding: '0px' }}
        data-testid="eventLists"
      >
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} style={{ padding: '0px' }}>
            <div className={classes.title}>
              <span>
                {!loader && !!maskedTotalRecords
                  ? `${maskedTotalRecords} Events`
                  : 'Events'}
              </span>
              <span className={classes.note}>
                This page can only show the latest 10,000 records.Contact
                support for details on your remaining records.
              </span>
            </div>
            <div className={classes.buttons}>
              <Button
                color="secondary"
                onClick={this.handleTableFilterToggle}
                data-testid="tableAddFilterButton"
              >
                <FontAwesomeIcon icon={faFilter} />
                <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
              </Button>
              <Button
                variant="outline"
                color="secondary"
                onClick={this.handleDownload}
                data-testid="tableDownloadButton"
              >
                <FontAwesomeIcon icon={faArrowDownToLine} />
                Download
              </Button>
            </div>
            {filtersShown && (
              <Grid container style={{ marginBottom: '12px' }}>
                <TableFilter
                  configs={filterConfigs}
                  candidateValues={candidateFilters}
                  appliedValues={appliedFilters}
                  onCandidateValuesChange={this.handleCandidateFiltersChange}
                  onAppliedValuesChange={this.handleAppliedFiltersChange}
                />
              </Grid>
            )}
            <Grid container justifyContent="center" spacing={0}>
              <Table
                testId="eventListingTable"
                className="events-listing-table"
                headRows={headRows}
                emptyState="no events to view"
                disableHover
                records={{ total: eventInfo.totalRecords }}
                loading={tableLoader}
                tableData={eventInfo.records.map((record, idx) => {
                  return (
                    <EventsListingRow key={`record_${idx}`} event={record} />
                  );
                })}
                handleChangePage={this.handleChangePage}
                createSortHandler={this.createSortHandler}
                filter={additionalFilters}
                pagination={maskedPaginationData}
                overLimitDisclaimer={overLimitDisclaimer}
              />
            </Grid>
          </Container>
        )}
      </section>
    );
  }
}

EventsList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLoadingMessage: PropTypes.func,
  clearLoadingMessage: PropTypes.func,
};

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoadingMessage: (message) =>
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    }),
  clearLoadingMessage: () =>
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventsList));
