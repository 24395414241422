import React, { FC } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../../constants';
import { convertTimeWithTimezone } from '../../../../../utils/time';
import { ExtVettingRecord, VettingAppeal } from '../../types';
import { isValidForOperation } from '../../utils';
import MoreButton from './MoreButton';
import ScoreTooltip from './ScoreTooltip';
import { VettingRecordAction } from './constants';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/pro-regular-svg-icons';

interface VettingDetailsRowProps {
  record: ExtVettingRecord;
  appeals: VettingAppeal[];
  onActionClick: (
    action: VettingRecordAction,
    data: ExtVettingRecord
  ) => Promise<void> | void;
  role: string;
}

const VettingDetailsRow: FC<VettingDetailsRowProps> = ({
  record: item,
  appeals,
  role,
  onActionClick,
}) => {
  const lowerCase = (vettingStatus: string) => {
    return vettingStatus.toLowerCase();
  };

  const displayVettingStatusColumn = (vettingStatus: string) => {
    return `${vettingStatus} ${
      lowerCase(vettingStatus) === 'unscore' ? ' (Under Review)' : ''
    }`;
  };

  const displayVettingDateColumn = (
    vettingStatus: string,
    date: number | Date
  ) => {
    const lowerStatus = lowerCase(vettingStatus);
    switch (lowerStatus) {
      case 'active':
        return convertTimeWithTimezone(date);
      case 'pending':
        return (
          <React.Fragment>
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xxs' }}>
                <FontAwesomeIcon icon={faHourglass} />
              </Box>
              Pending
            </Box>
          </React.Fragment>
        );
      case 'expired':
        return 'Expired';
      case 'contact_vendor':
        return 'Contact Vendor';
      case 'unscore':
      case 'failed':
        return date ? convertTimeWithTimezone(date) : '--';
      default:
        return '--';
    }
  };
  const displayVettingOutcomeColumn = (
    vettingStatus: string,
    score: number | null,
    evpUid: string
  ) => {
    const lowerStatus = lowerCase(vettingStatus);
    switch (lowerStatus) {
      case 'active':
        if (score !== null) {
          return `${score}`;
        }
        return evpUid === 'CV' ? 'Verified' : '--';
      case 'pending':
        return (
          <>
            <img src={`${S3_ASSETS_PATH}/images/vetting-pending.svg`} alt="" />
            Pending
          </>
        );
      case 'expired':
        return 'Expired';
      case 'contact_vendor':
        return 'Contact Vendor';
      case 'unscore':
        return 'Unscore';
      case 'failed':
        return 'Failed';
      default:
        return '--';
    }
  };

  const handleActionClick = (action: VettingRecordAction) => {
    onActionClick?.(action, item);
  };

  return (
    <TableRow className="contentRow">
      <TableCell
        className="gridItem"
        data-testid="brandVettingDetailsBlockContainerVettingClass"
      >
        {item.vettingClass}
      </TableCell>
      <TableCell
        className="gridItem"
        data-testid="brandVettingDetailsBlockContainerEvpUid"
      >
        {item.evpUid}
      </TableCell>
      <TableCell className="id">
        <div
          className="vettingIdWrapper id"
          data-testid="brandVettingDetailsBlockContainerStatus"
        >
          <p style={{ maxWidth: `70%` }}>
            {displayVettingStatusColumn(item.vettingStatus)}
          </p>
        </div>
      </TableCell>
      <TableCell
        className="gridItem date"
        data-testid="brandVettingDetailsBlockContainerDate"
      >
        <div className="inline-flex">
          {displayVettingDateColumn(item.vettingStatus, item.createDate)}
        </div>
      </TableCell>
      <TableCell
        className="gridItem outcome"
        data-testid="brandVettingDetailsBlockContainerOutcome"
      >
        <div className="inline-flex">
          {displayVettingOutcomeColumn(
            item.vettingStatus,
            item.score,
            item.evpUid
          )}
          <ScoreTooltip data={item} />
        </div>
      </TableCell>
      <TableCell
        className="gridItem action"
        data-testid="brandVettingDetailsBlockContainerAction"
      >
        {item.vettingClass === 'ENHANCED' && (
          <MoreButton
            data={item}
            menuClass="tcr-menu"
            data-testid="brandVettingDetailsBlockEnhancedMoreIconButton"
            items={[
              {
                label: 'View Report',
                onClick: () =>
                  handleActionClick(VettingRecordAction.ViewReport),
                disabled: item.vettingStatus.toLowerCase() === 'pending',
                'data-testid':
                  'brandVettingDetailsBlockEnhancedMoreMenuItemViewReport',
              },
              {
                label: 'Download Report',
                disabled: item.vettingStatus.toLowerCase() === 'pending',
                onClick: () =>
                  handleActionClick(VettingRecordAction.DownloadReport),
                'data-testid':
                  'brandVettingDetailsBlockEnhancedMoreMenuItemDownloadReport',
              },
            ]}
          />
        )}
        {item.vettingClass !== 'ENHANCED' && (
          <>
            <MoreButton
              menuClass="tcr-menu"
              data={item}
              data-testid="brandVettingDetailsBlockNonEnhancedMoreIconButton"
              items={[
                {
                  label: 'Appeal',
                  onClick: () => handleActionClick(VettingRecordAction.Appeal),
                  disabled:
                    (item.evpUid !== 'AEGIS' && item.evpUid !== 'WMC') ||
                    !isValidForOperation(role),
                  'data-testid':
                    'brandVettingDetailsBlockNonEnhancedMoreMenuItemAppeal',
                },
                {
                  label: 'View Appeal History',
                  onClick: () =>
                    handleActionClick(VettingRecordAction.ViewAppealHistory),
                  disabled: appeals.every(
                    (appeal) => appeal.vettingId !== item.vettingId
                  ),
                  'data-testid':
                    'brandVettingDetailsBlockNonEnhancedMoreMenuItemViewAppealHistory',
                  hidden: item.evpUid !== 'AEGIS',
                },
              ]}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default VettingDetailsRow;
