import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
  TextInput,
} from 'portal-commons';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, makeStyles } from '@material-ui/core';
import { faCircleUser, faInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROLE_DESCRIPTIONS } from '../constants';
import { UserCreatePayload } from '../types';
import { isRequired, isValidEmail, isValidName } from '../validators';
import { CustomTooltip } from '../../../../shared_elements';

interface UserCreateModalProps {
  open: boolean;
  disabled: boolean;
  roles: string[];
  onClose: () => void;
  onSubmit: (data: UserCreatePayload) => void;
}

const useStyles = makeStyles({
  container: {
    width: '525px',
    padding: '24px 32px 9px',
  },
});

const UserCreateModal: FunctionComponent<UserCreateModalProps> = ({
  open,
  disabled,
  roles,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const { handleSubmit, control, watch, reset } = useForm<UserCreatePayload>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      userAppRole: '',
    },
  });
  const options = useMemo(
    () =>
      roles.map((role) => ({
        label: role,
        value: role,
      })),
    [roles]
  );
  const role = watch('userAppRole');

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: 'column',
            rowGap: '8px',
            color: '#333E41',
            fontFamily: 'Roboto',
          }}
          data-testid="userCreateModal"
        >
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '12px',
              padding: '12px 0',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '28px',
            }}
          >
            <FontAwesomeIcon icon={faCircleUser} />
            <Box>New User</Box>
          </Flex>
          <Box
            sx={{
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '12px',
            }}
          >
            * Indicates a Required Field
          </Box>
          <Flex sx={{ flexDirection: 'column', rowGap: '16px' }}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  isValidName,
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  disabled={disabled}
                  error={!!error.message}
                  label="First Name"
                  note={error.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="firstName"
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  isValidName,
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  disabled={disabled}
                  error={!!error.message}
                  label="Last Name"
                  note={error.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="lastName"
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  isValidEmail,
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  disabled={disabled}
                  error={!!error.message}
                  label="Email"
                  note={error.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="email"
                />
              )}
            />
            <Flex
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                columnGap: '8px',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="userAppRole"
                  control={control}
                  rules={{
                    validate: {
                      isRequired,
                    },
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error = {} },
                  }) => (
                    <Dropdown
                      required
                      disabled={disabled}
                      error={!!error.message}
                      label="Role"
                      helperText={error.message}
                      options={options}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      data-testid="role"
                    />
                  )}
                />
              </Box>
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                }}
              >
                <CustomTooltip
                  arrow
                  placement="right"
                  title={
                    role
                      ? ROLE_DESCRIPTIONS[role]
                      : 'Select the role of the user'
                  }
                >
                  <FontAwesomeIcon icon={faInfo} color="#0091B3" />
                </CustomTooltip>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '20px',
              padding: '24px 0 40px',
            }}
          >
            <Button
              variant="outline"
              onClick={onClose}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={disabled} data-testid="saveButton">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Dialog>
  );
};

export default UserCreateModal;
