import React, { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { S3_ASSETS_PATH } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faCircleMinus,
} from '@fortawesome/pro-regular-svg-icons';

export default function ExpansionList(props) {
  const [expanded, toggleExpansion] = useState(
    props.expanded ? props.expanded : false
  );
  return (
    <ExpansionPanel
      square
      expanded={expanded}
      onChange={() => toggleExpansion(!expanded)}
      {...props}
      title={''}
    >
      <ExpansionPanelSummary
        expandIcon={
          expanded ? (
            props.docflag ? (
              <ExpandMoreIcon />
            ) : props.descflag ? (
              <img
                src={`${S3_ASSETS_PATH}/images/shrink-description-icon.svg`}
                alt="+"
              />
            ) : (
              <FontAwesomeIcon icon={faCircleMinus} size="2xs" />
            )
          ) : props.docflag ? (
            <ExpandMoreIcon />
          ) : props.descflag ? (
            <img
              src={`${S3_ASSETS_PATH}/images/expand-description-icon.svg`}
              alt="+"
            />
          ) : (
            <FontAwesomeIcon icon={faCirclePlus} size="2xs" />
          )
        }
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {expanded
          ? props.docflag
            ? props.title
            : props.children
          : props.title}
      </ExpansionPanelSummary>
      {props.docflag && expanded ? (
        <ExpansionPanelDetails>{props.children}</ExpansionPanelDetails>
      ) : null}
    </ExpansionPanel>
  );
}
