import React from 'react';
import { Grid, FormControlLabel, Link } from '@material-ui/core';
import CheckBox from '../../../../ui_elements/CheckBox';
import { S3_ASSETS_PATH } from '../../../../constants';
import parse from 'html-react-parser';
import { CustomTooltip } from '../../../../shared_elements';

const tooltipInfo = {
  '2FA': 'Any authentication, verification, or one-time passcode',
  ACCOUNT_NOTIFICATION:
    'Standard notifications for account holders, relating to and being about an account',
  CUSTOMER_CARE:
    'All customer interaction, including account management and customer support',
  DELIVERY_NOTIFICATION:
    'Information about the status of the delivery of a product or service',
  FRAUD_ALERT:
    'Messaging regarding potential fraudulent activity on an account',
  HIGHER_EDUCATION:
    'Campaigns created on behalf of Colleges or Universities. It also includes School Districts and education institutions that fall outside of any "free to the consumer" messaging model',
  LOW_VOLUME:
    'Small throughput, any combination of use-cases. Examples include: test, demo accounts.',
  MARKETING: 'Any communication with marketing and/or promotional content',
  MIXED: 'Any undeclared combination of use-cases',
  POLLING_VOTING: 'The sending of surveys and polling/voting campaigns',
  PUBLIC_SERVICE_ANNOUNCEMENT:
    "An informational message that is meant to raise the audience's awareness about an important issue",
  SECURITY_ALERT:
    'A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take',
  CARRIER_EXEMPT:
    'Exemption by Carrier. <br/> <span>!</span>Post-registration approval by MNO is required for this Use Case',
  CHARITY:
    'Communications from a registered charity aimed at providing help and raising money for those in need. 501c3 Tax-Exempt Organizations only.<br/><span>!</span> Post-registration approval by MNO is required for this Use Case',
  CONVERSATIONAL:
    'Peer-to-peer app-based group messaging with proxy/pooled numbers. Supporting personalized services and non-exposure of personal numbers for enterprise or A2P communications.<br/><span>!</span>Post-registration approval by MNO is required for this Use Case',
  EMERGENCY:
    'Notification services designed to support public safety / health during natural disasters, armed conflicts, pandemics and other national or regional emergencies. <br/> <span>!</span>Only MNO pre-approved CSPs are eligible for this Use Case',
  SWEEPSTAKE:
    'All sweepstakes messaging. <br/> <span>!</span>Post-registration approval by MNO is required for this Use Case',
  POLITICAL: (
    <div>
      Part of organized effort to influence decision making of specific groups.
      Available only to Non Profit entities with a Campaign Verify token or
      Aegis Political Vet, or Non Profit entities with a verified
      501(c)(3/4/5/6) tax exempt status.
      <br />
      <br />
      Register at&nbsp;
      <Link
        underline="hover"
        href="https://www.campaignverify.org/"
        target="_blank"
        rel="noreferrer noopener"
      >
        www.campaignverify.org
      </Link>
      &nbsp; and select 'Import Vetting' on the Brand Details page to unlock the
      Political Use Case, or apply for Aegis Political Vetting on the Brand
      Details page.
    </div>
  ),
  SOCIAL:
    "Communication within or between closed communities. (For example: influencers' alerts) <br/><span>!</span>Only MNO pre-approved CSPs are eligible for this Use Case",
  TRIAL:
    'Platform free trail offers with strict controls and MNO audit rights (MO opt in). <br/> <span>!</span>Only MNO pre-approved CSPs are eligible for this Use Case',
  AGENTS_FRANCHISES:
    'Brands that have multiple agents, franchises or offices in the same brand vertical, but require individual localised numbers per agent/location/office. <br/> <span>!</span>Post-registration approval by MNO is required for this Use Case',
  SOLE_PROPRIETOR: 'Sole Proprietor',
  PROXY:
    'Peer-to-peer app-based group messaging with proxy/pooled numbers. Supporting personalized services and non-exposure of personal numbers for enterprise or A2P communications.<br/><span>!</span>Post-registration approval by MNO is required for this Use Case',
  K12_EDUCATION:
    'Campaigns created for messaging platforms that support schools from grades K - 12 and distance learning centers. This is not for Post-Secondary schools. This use case requires approval from at least 1 MNO',
  UCAAS_HIGH:
    'UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication.  This is for UCaaS campaigns that require higher volume. This use case is only available to approved UCaaS businesses.',
  UCAAS_LOW:
    'UCaaS companies provide cloud delivered communication services for businesses. Each number assigned to a UCaaS campaign is typically assigned to a different employee of that business and the use cases are varied. This use case is not for any API/automated driven communication. This use case is only available to approved UCaaS businesses.',
  M2M: 'Machine-to-machine (M2M) is a process that implies wireless communication between two or more physical assets. There is no human interaction in the Machine to Machine campaign. Subscriber-facing campaigns are prohibited. This is a dedicated use case.',
};
export default function UsecasesList(props) {
  const {
    title,
    usecases,
    usecase,
    handleChange,
    type,
    campaignInfo,
    brandUsecases = [],
  } = props;

  const isQualifiedUsecase = (usecaseId) => {
    if (brandUsecases && brandUsecases.length) {
      const usecase = brandUsecases.find((item) => item.usecase === usecaseId);
      let qualify = false;
      if (usecase && usecase.mnoMetadata) {
        qualify = Object.keys(usecase.mnoMetadata).some(
          (key) => usecase.mnoMetadata[key].qualify === true
        );
      }
      // console.log(`qualify`, qualify)
      return qualify;
    } else {
      return false;
    }
  };

  return (
    <div
      className="use-cases-listing"
      data-testid="usercasesList"
      data-type={type}
    >
      <div className="heading">
        {type == 'STANDARD' ? (
          <img src={`${S3_ASSETS_PATH}/images/standard-campaign.svg`} />
        ) : (
          <img src={`${S3_ASSETS_PATH}/images/special-campaign.svg`} />
        )}
        <h3 className="heading2">{title}</h3>
      </div>
      <ul className="usecase-list list-unstyled">
        <li className="heading-row">
          <Grid container spacing={1}>
            <Grid item xs={8} style={{ paddingLeft: 40 }}>
              USE CASE
            </Grid>
            <Grid item xs={4}>
              TCR MONTHLY FEE
            </Grid>
          </Grid>
        </li>
        {usecases
          .filter((item) => item.classification == type)
          .map((item, index) => (
            <li
              className="contentRow"
              key={index}
              data-testid={`usercasesList-${index}`}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={8}
                  className="usecase-col"
                  data-testid="usercasesListUseCase"
                >
                  {/* <CheckBox checked={usecase.id == item.id ? true : false} handleCheckbox={() => {if(usecase.id == item.id){handleChange('', 'usecase')}else{handleChange(item, 'usecase')}}}/> */}
                  <FormControlLabel
                    control={
                      <CheckBox
                        disabled={isQualifiedUsecase(item.id) ? false : true}
                        checked={usecase.id == item.id ? true : false}
                        handleCheckbox={() => {
                          if (usecase.id == item.id) {
                            handleChange('', 'usecase');
                          } else {
                            handleChange(item, 'usecase');
                          }
                        }}
                      />
                    }
                    label={
                      <CustomTooltip
                        arrow
                        title={
                          <div className="tooltip-content">
                            {typeof tooltipInfo[item.id] === 'string'
                              ? parse(
                                  tooltipInfo[item.id]
                                    ? tooltipInfo[item.id]
                                    : ''
                                )
                              : tooltipInfo[item.id]}
                          </div>
                        }
                        placement="right"
                        interactive
                      >
                        <p
                          className="paragraph"
                          style={
                            isQualifiedUsecase(item.id) ? {} : { opacity: 0.5 }
                          }
                        >
                          {item.displayName}{' '}
                          {usecase.id == item.id && item.maxSubUsecases
                            ? `(${item.minSubUsecases}/${item.maxSubUsecases})`
                            : ''}
                        </p>
                      </CustomTooltip>
                    }
                    labelPlacement="end"
                  />
                  {/* <CustomTooltip arrow title={<div className="tooltip-content">{parse(tooltipInfo[item.id] ? tooltipInfo[item.id] : "")}</div>} placement="right">
                                <p className="paragraph">{item.displayName} {(usecase.id == item.id && item.maxSubUsecases) ? `(${item.minSubUsecases}/${item.maxSubUsecases})` : ''}</p>
                            </CustomTooltip> */}
                </Grid>
                <Grid item xs={4} data-testid="usercasesListMonthlyFee">
                  <p
                    className="paragraph fee"
                    style={isQualifiedUsecase(item.id) ? {} : { opacity: 0.5 }}
                  >{`$ ${item.monthlyFee}.-`}</p>
                </Grid>
              </Grid>
              {item.maxSubUsecases > 0 && usecase.id == item.id ? (
                <Grid container spacing={2} className="sub-usecases-listing">
                  {usecases
                    .filter((usecase) => usecase.validSubUsecase == true)
                    .map((subItem, index) => (
                      <Grid item xs={6} key={index}>
                        <div
                          className="sub-usecase-item usecase-col"
                          key={index}
                          data-testid="usercasesListValidSubUsecase"
                        >
                          <FormControlLabel
                            control={
                              <CheckBox
                                checked={
                                  campaignInfo.subUsecases.indexOf(
                                    subItem.id
                                  ) != -1
                                    ? true
                                    : false
                                }
                                disabled={
                                  campaignInfo.subUsecases.indexOf(
                                    subItem.id
                                  ) == -1 &&
                                  item.maxSubUsecases ==
                                    campaignInfo.subUsecases.length
                                    ? true
                                    : false
                                }
                                handleCheckbox={() =>
                                  handleChange(
                                    subItem.id,
                                    'subUsecases',
                                    'campaign'
                                  )
                                }
                              />
                            }
                            label={
                              <CustomTooltip
                                arrow
                                title={
                                  <div className="tooltip-content">
                                    {parse(
                                      tooltipInfo[subItem.id]
                                        ? tooltipInfo[subItem.id]
                                        : ''
                                    )}
                                  </div>
                                }
                                placement="right"
                              >
                                <p
                                  className="paragraph"
                                  style={
                                    campaignInfo.subUsecases.indexOf(
                                      subItem.id
                                    ) == -1 &&
                                    item.maxSubUsecases ==
                                      campaignInfo.subUsecases.length
                                      ? { opacity: 0.5 }
                                      : {}
                                  }
                                >
                                  {subItem.displayName}
                                </p>
                              </CustomTooltip>
                            }
                            labelPlacement="end"
                          />
                        </div>{' '}
                      </Grid>
                    ))}
                </Grid>
              ) : null}
            </li>
          ))}
      </ul>
    </div>
  );
}
