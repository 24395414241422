import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import {
  getAppealCategories,
  getVettingSettings,
} from './applications/CspPortal/CSP-Form/apiServices';
import {
  SET_APPEAL_CATEGORIES,
  SET_VETTING_SETTINGS,
} from './applications/CspPortal/CSP-Form/actions';
import {
  getArticle,
  getHelpAsides,
  getSimpleContent,
  getTermsAndConditions,
} from './shared_elements/apiServices';
import {
  SET_KNOWLEDGEBASE,
  SET_HELPBAR,
  SET_DYNAMIC_CONTENT,
  SET_TERMS_CONDITIONS,
} from './shared_elements/actions/index';

const Initializer: FunctionComponent = () => {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (!authState.isPending) {
        if (authState.isAuthenticated) {
          const appealCategories = await getAppealCategories();
          dispatch({
            type: SET_APPEAL_CATEGORIES,
            payload: appealCategories,
          });
          const vettingSettings = await getVettingSettings();
          dispatch({
            type: SET_VETTING_SETTINGS,
            payload: vettingSettings,
          });
          const article = await getArticle();
          dispatch({
            type: SET_KNOWLEDGEBASE,
            payload: article?.data.filter((item: any) =>
              ['review', 'published'].includes(item.status)
            ),
          });
          const helpAsides = await getHelpAsides();
          dispatch({
            type: SET_HELPBAR,
            payload: helpAsides?.data.filter((item: any) =>
              ['review', 'published'].includes(item.status)
            ),
          });
          const simpleContent = await getSimpleContent();
          dispatch({
            type: SET_DYNAMIC_CONTENT,
            payload: simpleContent?.data.filter((item: any) =>
              ['review', 'published'].includes(item.status)
            ),
          });
          const termsAndConditions = await getTermsAndConditions();
          dispatch({
            type: SET_TERMS_CONDITIONS,
            payload: termsAndConditions?.data.filter((item: any) =>
              ['review', 'published'].includes(item.status)
            ),
          });
        }
      }
    })();
  }, [authState.isPending, authState.isAuthenticated]);

  return null;
};

export default Initializer;
