import { Component } from 'react';
import {
  getBrandDetails,
  updateBrandApi,
  getExtVettingProviders,
  findAllVerticalsApi,
  importVettingRecordApi,
  grantExtVettingProviderBrandAccess,
  applyForNewVettingApi,
  getEvpsByVettingClassApi,
  listVettingRecordsApi,
  getAllStockExchanges,
  revetBrandApi,
  getBrandFeedbackApi,
  getUsStates,
  getCountries,
  getMnoSuspensionStatus,
  getAppealHistory,
  getAppealCategories,
} from '../apiServices';
import { connect } from 'react-redux';
import { Grid, Container } from '@material-ui/core';
import { isValidForPoliticalVet, isValidForWMC } from '../utils';
import {
  BrandNotFound,
  BrandDetailTopBlock,
  BrandBasicDetails,
  BrandIdentityStatus,
  BrandCompanyContactBlock,
  BrandSuspendedStatusBlock,
  BrandVettingDetailsBlock,
  BrandRegisteredCampaigns,
  PoliticalVettingModal,
  BrandVettingModal,
  VettingStatusModal,
  BrandEditModal,
  ConfirmationDialog,
} from '../components';
import { Loader } from '../../../../shared_elements';
import { isValidInput } from '../../../../utils';
import '../../../../assets/styles/brand-view-module.scss';
import { getMnoList } from '../../Campaigns/apiServices';
import {
  selectAppealCategories,
  selectVettingSettings,
} from '../../CSP-Form/selectors';
import { FeatureType, VettingType } from '../../CSP-Form/constants';

class BrandDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      brand: {},
      id: '',
      loader: true,
      modalOpenFlag: false,
      modalType: 1,
      vettingClass: '',
      evpUid: '',
      vettingId: '',
      checked: false,
      campaignBrandId: '',
      vettingProviders: [],
      vettingClasses: {},
      vettingLoader: false,
      error: {},
      importVettingError: false,
      vettingConfirmed: false,
      vettingRecords: [],
      applyError: false,
      verticalTypes: [],
      stockExchanges: [],
      goBackPage: '/brands',
      importVettingData: '',
      newVettingData: '',
      vettingToken: '',
      applyErrorMsg: '',
      revetConfirm: false,
      revetLoader: false,
      openEditModal: false,
      feedbackInfo: '',
      countryOptions: [],
      usStateOptions: [],
      notFound: false,
      mnos: [],
      appeals: [],
      appealCategories: [],
      identityAppealCategories: [],
      standardAppealCategories: [],
      validIdentityAppealCategoryIds: [],
      disabledIdentityAppealCategoryIds: [],
      validStandardAppealCategoryIds: [],
      disabledStandardAppealCategoryIds: [],
    };
    this.getExtVettingProviders = getExtVettingProviders.bind(this);
    this.getBrandDetails = getBrandDetails.bind(this);
    this.grantExtVettingProviderBrandAccess =
      grantExtVettingProviderBrandAccess.bind(this);
    this.findAllVerticalsApi = findAllVerticalsApi.bind(this);
    this.applyForNewVettingApi = applyForNewVettingApi.bind(this);
    this.getEvpsByVettingClassApi = getEvpsByVettingClassApi.bind(this);
    this.importVettingRecordApi = importVettingRecordApi.bind(this);
    this.listVettingRecordsApi = listVettingRecordsApi.bind(this);
    this.getAllStockExchanges = getAllStockExchanges.bind(this);
    this.updateBrandApi = updateBrandApi.bind(this);
    this.revetBrandApi = revetBrandApi.bind(this);
    this.getBrandFeedbackApi = getBrandFeedbackApi.bind(this);
    this.isValidForWMC = isValidForWMC.bind(this);
    this.isValidForPoliticalVet = isValidForPoliticalVet.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    document.title = 'The Campaign Registry - Brands';
    if (this.props.location.state) {
      const {
        modalOpenFlag = false,
        campaignBrandId = '',
        goBackPage = '/brands',
      } = this.props.location.state;
      this.setState((prevState) => ({
        ...prevState,
        modalOpenFlag,
        campaignBrandId,
        goBackPage,
      }));
    }
    const { id } = this.props.match.params;
    this.getBrandDetails(id);
    this.getExtVettingProviders();
    this.findAllVerticalsApi();
    this.getEvpsByVettingClassApi();
    this.listVettingRecordsApi(id);
    this.getAllStockExchanges();
    this.getBrandFeedbackApi(id);
    this.initialize();
    this.updateAppealCategories();
    this.updateAppealCategoryIds();
  }

  initialize = async () => {
    const { id } = this.props.match.params;
    try {
      const mnos = await getMnoList();
      mnos.sort((a, b) => {
        if (a.osrBitmaskIndex > b.osrBitmaskIndex) {
          return 1;
        } else if (a.osrBitmaskIndex < b.osrBitmaskIndex) {
          return -1;
        }
        return 0;
      });
      const records = await getMnoSuspensionStatus(id);
      const suspensionStatus = {};
      for (const record of records) {
        suspensionStatus[record.mnoId] = record.suspended;
      }
      this.setState({
        mnos: mnos.map((mno) => ({
          networkId: mno.networkId,
          displayName: mno.displayName,
          suspended: suspensionStatus[mno.networkId],
        })),
      });
    } catch (e) {}

    try {
      const { records } = await getAppealHistory(id);
      this.setState({
        appeals: records,
      });
    } catch (e) {}

    try {
      const appealCategories = await getAppealCategories();
      this.setState({
        appealCategories,
      });
    } catch (e) {}

    try {
      const countries = await getCountries();
      this.setState({
        countryOptions: Object.entries(countries)
          .sort(([key1, value1], [key2, value2]) => {
            if (value1 > value2) {
              return 1;
            } else if (value1 < value2) {
              return -1;
            }
            return 0;
          })
          .map(([key, value]) => ({
            label: value,
            value: key,
          })),
      });
    } catch (e) {}

    try {
      const usStates = await getUsStates();
      this.setState({
        usStateOptions: Object.entries(usStates)
          .sort(([key1, value1], [key2, value2]) => {
            if (value1 > value2) {
              return 1;
            } else if (value1 < value2) {
              return -1;
            }
            return 0;
          })
          .map(([key, value]) => ({
            label: value,
            value: key,
          })),
      });
    } catch (e) {}
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  filterVettingProviders = (vettingClass, modalType) => {
    const { brand, vettingClasses, vettingProviders } = this.state;
    const validForWMC = this.isValidForWMC(
      brand.entityType,
      brand.einIssuingCountry
    );
    if (vettingClass && modalType === 1) {
      return vettingClasses[vettingClass]
        .filter((item) => item.uid !== 'WMC' || validForWMC)
        .filter((item) => item.vettingInstruction.newVet?.supported === true);
    } else {
      return vettingProviders
        .filter((item) => item.uid !== 'WMC' || validForWMC)
        .filter(
          (item) => item.vettingInstruction.importVet?.supported === true
        );
    }
  };

  filterVettingClasses = () => {
    const { brand, vettingClasses } = this.state;
    const brandSupportPoliticalVet = this.isValidForPoliticalVet(
      brand.entityType,
      brand.einIssuingCountry
    );
    const isSupportNewVet = (vettingClass) =>
      vettingClasses[vettingClass].some(
        (item) => item.vettingInstruction.newVet?.supported === true
      );
    return Object.keys(vettingClasses)
      .filter((k) => k !== 'POLITICAL' || brandSupportPoliticalVet)
      .filter(isSupportNewVet)
      .filter((k) =>
        k === 'ENHANCED' && brand.entityType === 'SOLE_PROPRIETOR'
          ? false
          : true
      )
      .map((k) => ({ label: k, value: k }));
  };

  updateAppealCategories = () => {
    const { appealCategories } = this.props;
    const { brand } = this.state;
    if (appealCategories && brand) {
      const identityAppealCategories = appealCategories
        .filter((category) =>
          category.detail.some(
            (item) => item.vettingType === VettingType.Identity
          )
        )
        .map((category) => {
          const item = category.detail.find(
            (item) => item.vettingType === VettingType.Identity
          );
          return {
            appealCategoryId: category.appealCategoryId,
            displayName: category.displayName,
            description: item ? item.description : '',
          };
        });
      const standardAppealCategories = appealCategories
        .filter((category) =>
          category.detail.some(
            (item) => item.vettingType === VettingType.Standard
          )
        )
        .map((category) => {
          const item = category.detail.find(
            (item) => item.vettingType === VettingType.Standard
          );
          return {
            appealCategoryId: category.appealCategoryId,
            displayName: category.displayName,
            description: item ? item.description : '',
          };
        });
      this.setState({
        identityAppealCategories,
        standardAppealCategories,
      });
    }
  };

  updateAppealCategoryIds = () => {
    const { vettingSettings } = this.props;
    const { brand } = this.state;
    if (vettingSettings && brand) {
      const vettingSetting = vettingSettings.find(
        (item) => item.id === brand.entityType
      );
      if (vettingSetting) {
        const feature = vettingSetting.feature.find(
          (item) => item.id === FeatureType.Appeal
        );
        if (feature) {
          const validIdentityAppealCategoryIds = [];
          const disabledIdentityAppealCategoryIds = [];
          const validStandardAppealCategoryIds = [];
          const disabledStandardAppealCategoryIds = [];
          feature.evp.forEach((evp) => {
            evp.vettingType.forEach((vettingType) => {
              if (vettingType.id === VettingType.Identity) {
                vettingType.category.forEach((category) => {
                  validIdentityAppealCategoryIds.push(category.id);
                  if (
                    !category.allowAppealByIdentityStatus.includes(
                      brand.identityStatus
                    )
                  ) {
                    disabledIdentityAppealCategoryIds.push(category.id);
                  }
                });
              } else if (vettingType.id === VettingType.Standard) {
                vettingType.category.forEach((category) => {
                  validStandardAppealCategoryIds.push(category.id);
                  if (
                    !category.allowAppealByIdentityStatus.includes(
                      brand.identityStatus
                    )
                  ) {
                    disabledStandardAppealCategoryIds.push(category.id);
                  }
                });
              }
            });
          });
          this.setState({
            validIdentityAppealCategoryIds,
            disabledIdentityAppealCategoryIds,
            validStandardAppealCategoryIds,
            disabledStandardAppealCategoryIds,
          });
        }
      }
    }
  };

  handleModalClose = () => {
    const { campaignBrandId } = this.state;
    if (campaignBrandId) {
      this.props.history.push({
        pathname: '/campaign/create',
        state: { brandId: campaignBrandId },
      });
    }
    this.setState({
      modalOpenFlag: false,
      modalType: 1,
      campaignBrandId: '',
      evpUid: '',
      vettingClass: '',
      checked: false,
      vettingId: '',
      vettingToken: '',
      importVettingError: false,
      vettingLoader: false,
      vettingConfirmed: false,
      applyError: false,
      error: {},
      applyErrorMsg: '',
    });
  };

  handleChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      evpUid: key === 'vettingClass' ? '' : prevState.evpUid,
      vettingToken: key === 'evpUid' ? '' : prevState.vettingToken,
      [key]: value,
      error: {
        ...prevState.error,
        vettingToken: key === 'evpUid' ? '' : prevState.error.vettingToken,
        [key]: '',
      },
    }));
  };

  handleSubmitVetting = (e) => {
    e.preventDefault();
    const {
      id,
      evpUid,
      modalType,
      vettingClass,
      vettingId,
      error,
      vettingToken,
    } = this.state;
    this.setState({ vettingLoader: true });
    if (modalType === 1) {
      if (vettingClass === 'POLITICAL' && evpUid === 'AEGIS') {
        this.setState({
          modalType: 5,
        });
      } else {
        this.applyForNewVettingApi(id, evpUid, vettingClass, {
          vettingClass,
        });
      }
    } else if (
      vettingId.trim() &&
      (evpUid === 'AEGIS' ? vettingToken.trim() : true)
    ) {
      if (
        isValidInput(vettingId) &&
        (evpUid === 'AEGIS' ? isValidInput(vettingToken) : true)
      ) {
        this.importVettingRecordApi(
          id,
          evpUid,
          vettingId.trim(),
          evpUid === 'AEGIS'
            ? { vettingToken: encodeURIComponent(vettingToken) }
            : {}
        );
      } else {
        this.setState({
          error: {
            vettingId: isValidInput(vettingId) ? '' : 'Invalid input',
            vettingToken:
              evpUid === 'AEGIS'
                ? isValidInput(vettingToken)
                  ? ''
                  : 'Invalid input'
                : '',
          },
          vettingLoader: false,
        });
      }
    } else {
      this.setState({
        error: {
          vettingId: vettingId.trim() ? '' : 'Please enter Vetting Identifier',
          vettingToken:
            evpUid === 'AEGIS'
              ? vettingToken.trim()
                ? isValidInput(vettingToken)
                  ? ''
                  : 'Invalid input'
                : 'Please enter Vetting Token'
              : '',
        },
        vettingLoader: false,
        vettingId: '',
        vettingToken: '',
      });
    }
  };

  handleSubmitPoliticalVetting = (data) => {
    const { id, evpUid, vettingClass } = this.state;
    this.setState({ vettingLoader: true });
    this.applyForNewVettingApi(id, evpUid, vettingClass, {
      vettingClass,
      additionalRequestPayload: JSON.stringify(data),
    });
  };

  handleCheckBox = () => {
    this.setState((prevState) => ({
      ...prevState,
      checked: !prevState.checked,
    }));
  };

  handleError = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: value
          ? ''
          : `Please enter ${
              key === 'vettingId' ? 'Vetting Identifier' : 'Vetting Token'
            }`,
      },
    }));
  };

  handleEditClick = (type) => {
    this.setState({
      openEditModal: true,
      editType: type,
    });
  };

  handleSubmitEdit = (data) => {
    const { editType, id } = this.state;
    this.updateBrandApi(id, data);
  };

  handleGoBack = () => {
    const { goBackPage } = this.state;
    if (goBackPage) {
      this.props.history.push(`${goBackPage}`);
    } else {
      // Safari fall back
      window.history.back();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cspData.length && this.state.importVettingData === '') {
      const importVettingData = this.props.cspData.find(
        (item) => item.lookup_key == 'csp-brand-vetting-modal#import-vetting'
      );
      this.setState({
        importVettingData,
      });
    }
    if (this.props.cspData.length && this.state.newVettingData === '') {
      const newVettingData = this.props.cspData.find(
        (item) => item.lookup_key == 'csp-brand-vetting-modal#new-vetting'
      );
      this.setState({
        newVettingData,
      });
    }
    if (
      this.props.appealCategories !== prevProps.appealCategories ||
      this.state.brand !== prevState.brand
    ) {
      this.updateAppealCategories();
    }
    if (
      this.props.vettingSettings !== prevProps.vettingSettings ||
      this.state.brand !== prevState.brand
    ) {
      this.updateAppealCategoryIds();
    }
  }

  handleSubmitRevet = (flag) => {
    if (flag) {
      this.revetBrandApi();
    } else {
      this.setState({
        revetConfirm: false,
      });
    }
  };

  handleAppealCreate = (appeal) => {
    const { appeals } = this.state;
    this.setState({
      appeals: [appeal, ...appeals],
    });
  };

  handleState = (newStateObj) => {
    Object.entries(newStateObj).forEach(([stateKey, newVal]) => {
      this.setState({
        [stateKey]: newVal,
      });
    });
  };

  hasEditPermission = (role) => {
    const allowedRoles = ['ROLE_USER', 'ROLE_MANAGER'];
    return allowedRoles.includes(role);
  };

  render() {
    const {
      brand,
      loader,
      id,
      vettingClasses,
      vettingClass,
      vettingProviders,
      vettingLoader,
      modalOpenFlag,
      modalType,
      vettingId,
      vettingConfirmed,
      vettingRecords,
      applyError,
      vettingScore,
      openEditModal,
      editType,
      stockExchanges,
      verticalTypes,
      editLoader,
      error,
      goBackPage,
      evpUid,
      importVettingError,
      importVettingData,
      newVettingData,
      vettingToken,
      applyErrorMsg,
      revetConfirm,
      revetLoader,
      feedbackInfo,
      notFound,
      appeals,
      appealCategories,
      identityAppealCategories,
      standardAppealCategories,
      validIdentityAppealCategoryIds,
      disabledIdentityAppealCategoryIds,
      validStandardAppealCategoryIds,
      disabledStandardAppealCategoryIds,
    } = this.state;

    if (notFound) {
      return <BrandNotFound />;
    }
    return (
      <section className="brand-view-module" data-testid="brandDetails">
        {loader ? (
          <Loader />
        ) : (
          <Container
            maxWidth={false}
            style={{ padding: '0px' }}
            className="brand-view-container"
            data-testid="brandDetailsContianer"
          >
            <BrandDetailTopBlock
              backLink={goBackPage}
              handleGoBack={this.handleGoBack}
            />

            <BrandBasicDetails
              brandUid={id}
              brand={brand}
              role={this.props.role}
              handleEditClick={this.handleEditClick}
              hasEditPermission={this.hasEditPermission}
              getBrandDetails={this.getBrandDetails}
            />

            <BrandIdentityStatus
              role={this.props.role}
              brand={brand}
              feedbackInfo={feedbackInfo}
              appeals={appeals.filter(
                (appeal) => appeal.vettingClass === 'IDENTITY'
              )}
              appealCategories={identityAppealCategories}
              validAppealCategoryIds={validIdentityAppealCategoryIds}
              disabledAppealCategoryIds={disabledIdentityAppealCategoryIds}
              onAppealCreate={this.handleAppealCreate}
              onResubmitClick={() => this.setState({ revetConfirm: true })}
            />

            <Grid
              container
              spacing={0}
              className="brand-details more"
              justifyContent="center"
            >
              <BrandCompanyContactBlock
                brand={brand}
                role={this.props.role}
                handleEditClick={this.handleEditClick}
                hasEditPermission={this.hasEditPermission}
              />

              <BrandSuspendedStatusBlock mnos={this.state.mnos} />

              <BrandVettingDetailsBlock
                role={this.props.role}
                brand={brand}
                records={vettingRecords}
                appeals={appeals.filter(
                  (appeal) => appeal.vettingClass === 'STANDARD'
                )}
                appealCategories={standardAppealCategories}
                validAppealCategoryIds={validStandardAppealCategoryIds}
                disabledAppealCategoryIds={disabledStandardAppealCategoryIds}
                feedbackInfo={feedbackInfo ? feedbackInfo : []}
                onAppealCreate={this.handleAppealCreate}
                hasEditPermission={this.hasEditPermission}
                handleState={this.handleState}
              />

              <BrandRegisteredCampaigns brandUid={id} />
            </Grid>
            {/* APPLY FOR NEW VETTING - Modal type 1, IMPORT VETTING - Modal type 3  */}
            {modalOpenFlag ? (
              modalType === 1 || modalType === 3 ? (
                <BrandVettingModal
                  vettingProviders={this.filterVettingProviders(
                    vettingClass,
                    modalType
                  )}
                  vettingClasses={this.filterVettingClasses()}
                  handleClose={this.handleModalClose}
                  extVettingProviderId={evpUid}
                  vettingId={vettingId}
                  handleChange={this.handleChange}
                  modalType={modalType}
                  error={error}
                  submitVettingRequest={this.handleSubmitVetting}
                  handleCheckBox={this.handleCheckBox}
                  checked={true}
                  vettingLoader={vettingLoader}
                  vettingClass={vettingClass}
                  importVettingError={importVettingError}
                  handleError={this.handleError}
                  newVettingData={newVettingData}
                  importVettingData={importVettingData}
                  vettingToken={vettingToken}
                />
              ) : modalType === 5 ? (
                <PoliticalVettingModal
                  brand={brand}
                  onClose={this.handleModalClose}
                  onSubmit={this.handleSubmitPoliticalVetting}
                />
              ) : (
                <VettingStatusModal
                  vettingProviders={
                    vettingClass && modalType === 1
                      ? vettingClasses[vettingClass]
                      : vettingProviders
                  }
                  handleClose={this.handleModalClose}
                  extVettingProviderId={evpUid}
                  vettingId={vettingId}
                  vettingToken={vettingToken}
                  vettingScore={vettingScore}
                  modalType={modalType}
                  brandName={brand.displayName}
                  vettingLoader={vettingLoader}
                  vettingClass={vettingClass}
                  vettingConfirmed={vettingConfirmed}
                  applyError={applyError}
                  applyErrorMsg={applyErrorMsg}
                  importVettingError={importVettingError}
                />
              )
            ) : null}
            <BrandEditModal
              open={openEditModal}
              handleClose={() => this.setState({ openEditModal: false })}
              type={editType}
              brandInfo={brand}
              stockExchanges={stockExchanges}
              verticalTypes={verticalTypes}
              countries={this.state.countryOptions}
              usStates={this.state.usStateOptions}
              loader={editLoader}
              handleSubmitEdit={this.handleSubmitEdit}
            />
          </Container>
        )}
        <ConfirmationDialog
          open={revetConfirm}
          content={`Are you sure? <span>You will be charged $4</span><br/> every time you resubmit a Brand<br/> for identity verification.`}
          submitText={'Proceed'}
          handleClick={this.handleSubmitRevet}
          loader={revetLoader}
        />
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
  appealCategories: selectAppealCategories(state),
  vettingSettings: selectVettingSettings(state),
});
export default connect(mapStateToProps)(BrandDetails);
