import React, { Fragment } from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import ToolTip from '../../../../ui_elements/ToolTip';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';

const CspContactDetail = (props) => {
  const { cspInfo, error, brandRegisterFlag, openTooltip } = props;
  return (
    <Fragment>
      {/* {!brandRegisterFlag && <Grid container spacing = {4}>
              <Grid item xs={12} md={6}>
                  <div className="form-group-field">
                    <TextField
                      error={ error.firstName ? true:false }
                      value={ cspInfo.firstName }
                      id="firstName"
                      required
                      fullWidth={true}
                      label="First Name"
                      inputProps={{ 'aria-label': 'firstName' }}
                      onChange={(event) => props.handleChange(event.target.value, 'firstName')}
                      onBlur={(event) => props.handleError(event.target.value,'firstName')}
                    />
                    {error.firstName ? <h6 className="error-msg">{error.firstName}</h6>:null}
                  </div>
              </Grid>
              <Grid item xs={12} md={6}>
                  <div className="form-group-field">
                    <TextField
                      error={ error.lastName ? true:false }
                      value={ cspInfo.lastName }
                      id="lastName"
                      required
                      fullWidth={true}
                      label="Last Name"
                      inputProps={{ 'aria-label': 'lastName' }}
                      onChange={(event) => props.handleChange(event.target.value, 'lastName')}
                      onBlur={(event) => props.handleError(event.target.value,'lastName')}
                    />
                    {error.lastName ? <h6 className="error-msg">{error.lastName}</h6>:null}
                  </div>
              </Grid>
        </Grid>} */}
      <Grid container spacing={4} data-testid="cspContactDetail">
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <Fragment>
              {brandRegisterFlag && (
                <ToolTip
                  title="We might send an email to this address for verification purposes"
                  open={openTooltip}
                />
              )}
              <TextField
                data-testid="cspContactDetailEmailInput"
                error={error.email ? true : false}
                value={cspInfo.email}
                id="email"
                required
                fullWidth={true}
                label={
                  brandRegisterFlag ? 'Support Email Address' : 'Email Address'
                }
                inputProps={{ 'aria-label': 'email' }}
                onChange={(event) =>
                  props.handleChange(event.target.value, 'email')
                }
                onBlur={(event) =>
                  props.handleError(event.target.value, 'email')
                }
                onFocus={props.handleFocus ? props.handleFocus : null}
              />
            </Fragment>
            {error.email ? (
              <h6 className="error-msg">
                {brandRegisterFlag
                  ? `${error.email} Support Email Address`
                  : `${error.email} Email Address`}
              </h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="form-group-field">
            <PhoneInput
              data-testid="cspContactDetailPhoneInput"
              flags={flags}
              country="US"
              value={cspInfo.phone}
              countryOptions={['US', 'IT', 'IN', '|']}
              onChange={(telNumber) => props.handleChange(telNumber, 'phone')}
              limitMaxLength
              placeholder={
                brandRegisterFlag ? 'Support Phone Number' : 'Phone Number'
              }
              onCountryChange={(countryIsoCode) =>
                props.handleChange(countryIsoCode, 'countryIsoCode phone')
              }
              onBlur={(e) => props.handleError(e.target.value, 'phone')}
              inputClassName={error.phone ? 'error' : ''}
            />
            {error.phone ? <h6 className="error-msg">{error.phone}</h6> : null}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
CspContactDetail.propTypes = {
  cspInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  error: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleError: PropTypes.func,
};
export default CspContactDetail;
