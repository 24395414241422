export const isValidForPoliticalVet = (entityType, einIssuingCountry) =>
  (entityType === 'SOLE_PROPRIETOR' || entityType === 'NON_PROFIT') &&
  einIssuingCountry === 'US';

export const isValidForWMC = (entityType, einIssuingCountry) =>
  (entityType === 'PRIVATE_PROFIT' || entityType === 'PUBLIC_PROFIT') &&
  einIssuingCountry === 'US';

export const isValidForOperation = (role) =>
  ['ROLE_MANAGER', 'ROLE_USER'].includes(role);
