import {
  globalPostService,
  globalGetService,
  globalPutService,
  globalDeleteService,
} from '../../../utils/globalApiServices';
import queryString from 'query-string';

export function getResellerListApi(query = {}) {
  this.setState({ tableLoader: true });
  globalGetService(`csp/reseller`, query).then((response) => {
    this.setState({ tableLoader: false });
    if (response && response.status >= 200 && response.status < 300) {
      const resellerInfo = response.data;
      this.setState((prevStata) => ({
        ...prevStata,
        resellerInfo,
        loader: false,
        noResellersFound: response.data.totalRecords == 0 ? true : false,
      }));
      if (
        resellerInfo.records.length == 0 &&
        resellerInfo.totalRecords > 0 &&
        resellerInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(resellerInfo.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: false }
          )}`,
        });
        // this.getCampaignsListApi({...query, page: lastPageNo})
      }
    }
  });
}
export function deleteResellerApi(resellerUid) {
  return globalDeleteService(`csp/reseller/${resellerUid}`);
}
export function addNewResellerApi(data) {
  return globalPostService(`csp/reseller`, data);
}
export function updateResellerApi(resellerUid, formData) {
  return globalPutService(`csp/reseller/${resellerUid}`, formData);
}
