import React from 'react';
import { Grid } from '@material-ui/core';
import BrandCompanyDetails from '../BrandCompanyDetails';

import { S3_ASSETS_PATH } from '../../../../../constants';
import { BrandDetail } from '../../types';
import BrandContactDetails from '../BrandContactDetails';
import BrandRelationshipDetails from '../BrandRelationshipDetails';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuildingCircleCheck,
  faPen,
  faUserCircle,
  faChartNetwork,
} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  brand: BrandDetail;
  role: string;
  handleEditClick: (_type: string) => void;
  hasEditPermission: (_rold: string) => boolean;
}
const BrandCompanyContactBlock: React.FC<Props> = ({
  brand,
  role,
  handleEditClick,
  hasEditPermission,
}) => {
  return (
    <Grid item xs={12} data-testid="brandCompanyContactBlock">
      <div className="company details">
        <div className="details-heading">
          <div className="title">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faBuildingCircleCheck} size="xl" />
              </Box>
              <h3 className="heading1">Company Details</h3>
            </Box>
          </div>
          {hasEditPermission(role) && (
            <React.Fragment>
              <div
                className="edit-btn"
                onClick={() => handleEditClick('company')}
                data-testid="brandCompanyContactBlockEditCompanyButton"
              >
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faPen} color="#0091B3" />
                  </Box>
                  <span>Edit</span>
                </Box>
              </div>
            </React.Fragment>
          )}
        </div>
        <BrandCompanyDetails brand={brand} />

        <div className="details-heading contact-details">
          <div className="title">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faUserCircle} size="xl" />
              </Box>
              <h3 className="heading1">Contact Details</h3>
            </Box>
          </div>
        </div>
        <BrandContactDetails brand={brand} />

        <div className="details-heading contact-details">
          <div className="title">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faChartNetwork} size="xl" />
              </Box>
              <h3 className="heading1">Brand Relationship</h3>
            </Box>
          </div>
        </div>
        <BrandRelationshipDetails brand={brand} />
      </div>
    </Grid>
  );
};

export default BrandCompanyContactBlock;
