import { getCampaignRoles, getMigrationCNP } from './apiServices';

export const charCounter = ({ value, maxLength }) => {
  let count = 0;
  if (value && value.length) {
    count = value.length;
  }
  if (maxLength) {
    return `${count} / ${maxLength}`;
  }
};

export const remapTCRStatus = (status) => {
  if (status === 'ACTIVE') {
    return status;
  } else if (status === 'EXPIRED') {
    return 'DEACTIVATED';
  } else {
    return 'N/A';
  }
};

const isValidCampaignRole = (role) => {
  return role && !['NONE', 'UNSET'].includes(role);
};

export const getMigrationAndProvisionalInfo = async (campaignUid) => {
  let provisional = false;
  const migrationInfo = await getMigrationCNP(campaignUid, null, {
    silent: true,
  });

  if (migrationInfo?.status === 'ACTIVE') {
    try {
      const roles = await getCampaignRoles(campaignUid);
      provisional = isValidCampaignRole(roles?.provisionalRole);
    } catch {
      // silently let provisional falls back to false and returns to page
    }
  }

  return {
    migrationInfo,
    provisional,
  };
};
