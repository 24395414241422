import { useState, useEffect, useRef } from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

import { BoxV2 as Box, Button, Flex } from 'portal-commons';
import { faBuildingCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { connect } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import countryList from 'react-select-country-list';

import { CspBasicDetailsNew, CspPaymentInfo, Stepper } from '../components';
import {
  createNewCspProfileApi,
  getCspProfileApi,
  appealCspProfileApi,
  getAllStockExchanges,
  getCspValidationRulesApi,
} from '../apiServices';
import { CSP_Info } from '../../index';
import { US_STATE_LIST } from '../../../../constants';
import { HelpSection } from '../../../../shared_elements';
import { getActivationFeeAc } from '../actionCreators';
import '../../../../assets/styles/csp-register-module.scss';
import { getCountries } from '../../Brands/apiServices';
import {
  CnpPartnerInfo,
  Section,
  ContactDetails,
} from '../components/CspVerificationSteps';

const MAX_STEP = 4;

const CspVerificationForm = ({ location, cspData, defaultStep = 1 }) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(defaultStep);
  const [countries, setCountries] = useState([]);
  const [validationRules, setValidationRules] = useState([]);
  const [stockExchanges, setStockExchanges] = useState([]);
  const [checked, setChecked] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState('');
  const [countryIsoCode, setCountryIsoCode] = useState({
    phone: 'US',
    refDcaContactPhone: 'US',
  });
  const [actBtnVisibility, setActBtnVisibility] = useState(defaultStep > 1);
  const [direction, setDirection] = useState('next');
  const [terminated, setTerminated] = useState(false);
  const isEditMode = location.pathname === '/user/edit/csp';
  const history = useHistory();
  const form = useForm({
    defaultValues: {
      ...CSP_Info,
      financeEmail: [{ email: '' }],
      email: [{ email: '' }],
      techEmail: [{ email: '' }],
      escalateEmail: [{ email: '' }],
    },
    mode: 'onChange',
  });
  const stepRef = useRef();
  const [has2ndCnp, setHas2ndCnp] = useState('no');
  const isLastStep = step >= MAX_STEP;

  const {
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
    handleSubmit,
    trigger,
  } = form;
  const cspInfo = getValues();

  useEffect(() => {
    document.title = 'The Campaign Registry - CSP Verification';
    if (isEditMode) {
      getCspProfileApi().then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const cspInfo = response.data;
          cspInfo.country = countryList()
            .getData()
            .find((country) => country.value === cspInfo.country);
          cspInfo.refDcaContactEmail = cspInfo.refDcaEmail;
          cspInfo.refDcaContactPhone = cspInfo.refDcaPhone;
          if (cspInfo.country.value === 'US') {
            cspInfo.state = US_STATE_LIST.find(
              (state) => state.value === cspInfo.state
            );
          }
          reset(cspInfo);
        }
      });
    } else {
      if (
        localStorage.getItem('cspStatus') &&
        localStorage.getItem('cspStatus') === 'active'
      ) {
        history.push('/dashboard');
      }
      const userData =
        localStorage.getItem('okta-token-storage') &&
        JSON.parse(
          atob(
            `${
              JSON.parse(localStorage.getItem('okta-token-storage')).accessToken
                .accessToken
            }`.split('.')[1]
          )
        );
      const { companyName } = userData;
      setValue('companyName', companyName);
      setValue('displayName', companyName);
      setValue('phone', userData.mobilePhone || '');
      setValue('email', [{ email: userData.sub }]);
    }
    updateAllStockExchanges();
    updateValidationRules();
    initializeCountries();
  }, []);

  useEffect(() => {
    const paymentInfo = cspData.find(
      (item) => item.lookup_key === 'csp-payment-info'
    );
    setPaymentInfo(paymentInfo);
  }, [cspData]);

  const updateAllStockExchanges = () => {
    getAllStockExchanges().then((stockExchanges) => {
      setStockExchanges(stockExchanges);
    });
  };

  const updateValidationRules = () => {
    getCspValidationRulesApi().then((validationRules) => {
      setValidationRules(validationRules);
    });
  };

  const initializeCountries = async () => {
    const response = await getCountries();
    const countries = Object.entries(response)
      .sort(([key1, value1], [key2, value2]) => {
        if (value1 > value2) {
          return 1;
        } else if (value1 < value2) {
          return -1;
        }
        return 0;
      })
      .map(([key, value]) => ({
        label: value,
        value: key,
      }));
    setCountries(countries);
  };

  const setPartialCspInfo = (values) => {
    if (values && typeof values === 'object') {
      Object.keys(values).entries(([key, val]) => {
        setValue(key, val);
      });
    }
  };

  const handleChange = (value, key) => {
    if (key.includes('countryIsoCode')) {
      if (key === 'countryIsoCode phone') {
        setCountryIsoCode((prev) => ({
          ...prev,
          phone: value,
        }));
      } else {
        setCountryIsoCode((prev) => ({
          ...prev,
          refDcaContactPhone: value,
        }));
      }
    } else {
      setPartialCspInfo({
        [key]: value,
      });
      clearErrors(key);
    }
  };

  const goToPreviousStep = () => {
    if (!stepRef.current?.next || !stepRef.current.prev()) {
      setDirection('prev');
      setStep(step - 1);
    }
  };

  const goToNextStep = () => {
    if (!stepRef.current?.next || !stepRef.current.next()) {
      setDirection('next');
      setStep(step + 1);
    }
  };

  const handleNextClick = async () => {
    const isValid = await trigger();
    if (isValid) {
      goToNextStep();
    }
  };

  const emailArrayToString = (emailArray) => {
    if (Array.isArray(emailArray)) {
      return emailArray.map((value) => value.email?.trim() ?? '').join(',');
    }
    return emailArray;
  };

  const submitCspVerification = () => {
    const { displayName, country, state, phone, refDcaContactPhone } = cspInfo;
    const countryVal = typeof country === 'object' ? country.value : country;
    const stateVal = typeof state === 'object' ? state.value : state;
    if (!isLastStep) {
      goToNextStep();
      return;
    }
    setLoading(true);
    if (checked) {
      const formData = {
        ...cspInfo,
        country: countryVal,
        phone: formatPhoneNumberIntl(phone)
          ? formatPhoneNumberIntl(phone)
          : phone,
        refDcaContactPhone: formatPhoneNumberIntl(refDcaContactPhone)
          ? formatPhoneNumberIntl(refDcaContactPhone)
          : refDcaContactPhone,
        ...(displayName === '' ? { displayName: formData.companyName } : null),
        email: emailArrayToString(cspInfo.email),
        financeEmail: emailArrayToString(cspInfo.financeEmail),
        escalateEmail: emailArrayToString(cspInfo.escalateEmail),
        techEmail: emailArrayToString(cspInfo.techEmail),
      };
      if (formData.country === 'US') {
        formData.state = stateVal;
      }
      if (!isEditMode) {
        createNewCspProfileApi(formData)
          .then((res) => {
            if (res.ok) {
              history.push('/user/csp/status');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        appealCspProfileApi(formData)
          .then((res) => {
            if (res.ok) {
              history.push('/user/csp/status');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <section
      className="csp-verification-section"
      data-testid="cspVerificationForm"
    >
      <Container maxWidth={false} style={{ padding: '0px' }}>
        <Box sx={{ textAlign: 'center', mb: 'xxxl' }}>
          <Stepper
            activeKey={step}
            disabled={terminated}
            steps={[
              { key: 1, label: 'Register' },
              { key: 2, label: 'CSP Details' },
              { key: 3, label: 'Contact Details' },
              { key: 4, label: 'Terms & Conditions' },
            ]}
          />
        </Box>
        {terminated && (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
            <Flex
              sx={{
                flexDirection: 'column',
                gap: 'l',
                border: '1px solid',
                borderColor: '#C0CCD4',
                background: 't.white',
                padding: 'xxl',
                pt: 'xl',
              }}
            >
              <Box
                as="p"
                sx={{
                  width: '534px',
                  color: '#333E41',
                  textAlign: 'center',
                  fontSize: 'H500',
                  lineHeight: 'normal',
                }}
              >
                Currently, direct registration with TCR is not available for
                Brands. Instead, you must work with one of the registered
                messaging service providers (CSP) who will handle your
                registration process on your behalf.
              </Box>
            </Flex>
          </Flex>
        )}
        {!terminated && (
          <FormProvider {...form}>
            <form noValidate>
              <div className="csp-verification-form">
                {step === 1 && (
                  <CnpPartnerInfo
                    ref={stepRef}
                    backwards={direction === 'prev'}
                    setActBtnVisibility={setActBtnVisibility}
                    setHas2ndCnp={setHas2ndCnp}
                    has2ndCnp={has2ndCnp}
                    onTerminate={() => setTerminated(true)}
                  />
                )}
                {step === 2 && (
                  <Section
                    title="CSP Details"
                    icon={faBuildingCircleCheck}
                    data-testid="cspVerificationFormCspDetails"
                  >
                    <CspBasicDetailsNew
                      cspInfo={cspInfo}
                      error={errors}
                      stockExchanges={stockExchanges}
                      countries={countries}
                      validationRules={validationRules}
                    />
                  </Section>
                )}
                {step === 3 && (
                  <ContactDetails
                    ref={stepRef}
                    csp={cspInfo}
                    handleChange={handleChange}
                    countryIsoCode={countryIsoCode}
                    backwards={direction === 'prev'}
                  />
                )}
                {step === 4 && (
                  <CspPaymentInfo
                    checked={checked}
                    handleCheck={() => setChecked(!checked)}
                    paymentInfo={paymentInfo}
                    loader={loading}
                  />
                )}
              </div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 's',
                  mt: 'xl',
                }}
              >
                {actBtnVisibility && (
                  <Button
                    type="button"
                    variant="outline"
                    data-testid="cspVerificationFormPreviousButton"
                    onClick={goToPreviousStep}
                  >
                    Previous
                  </Button>
                )}
                {actBtnVisibility &&
                  (isLastStep ? (
                    <Button
                      data-testid="cspVerificationFormNextButton"
                      variant="standard"
                      type="button"
                      disabled={loading || !checked}
                      onClick={handleSubmit(submitCspVerification)}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      data-testid="cspVerificationFormNextButton"
                      variant="standard"
                      type="button"
                      onClick={handleNextClick}
                    >
                      Next
                    </Button>
                  ))}
              </Box>
            </form>
          </FormProvider>
        )}
      </Container>
      <HelpSection path="/user/csp/create" />
    </section>
  );
};
const mapStateToProps = (state) => ({
  activationFee: state.CSPReducer.activationFee,
  cspData: state.shareReducer.cspData,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getActivationFee: () => dispatch(getActivationFeeAc()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CspVerificationForm);
