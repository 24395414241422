import React, { useState } from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  US_STATE_LIST,
  ENTITY_TYPES,
  S3_ASSETS_PATH,
  OPEN_CANADIAN_BRAND_NOTICE,
  BRAND_RELATIONSHIP_SCORE,
} from '../../../../../constants';
import ToolTip from '../../../../../ui_elements/ToolTip';
import IntegrationReactSelect from '../../../../../ui_elements/IntegrationReactSelect';
import PhoneInput, { Country, Value } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import CanadianBrandNoticeModal from '../CanadianBrandNoticeModal/CanadianBrandNoticeModal';
import { BrandDetail } from '../../types';
import { SelectItem } from '../../../../../utils/types';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faChartNetwork,
} from '@fortawesome/pro-regular-svg-icons';

type KeyOfBrand = keyof BrandDetail;
interface Props {
  brand: BrandDetail;
  handleChange: (_value: BrandDetail[KeyOfBrand], key: KeyOfBrand) => void;
  handleError: (_value: string, key: string) => void;
  error: Record<string, string>;
  stockExchanges: SelectItem[];
  verticalTypes: SelectItem[];
  countries: SelectItem[];
  usStates: SelectItem[];
  setCountryIsoCode: React.Dispatch<React.SetStateAction<Country | undefined>>;
}

const EditBrandCompanyDetails: React.FC<Props> = ({
  brand,
  handleChange,
  handleError,
  error,
  stockExchanges,
  verticalTypes,
  countries,
  usStates,
  setCountryIsoCode,
}) => {
  const options =
    brand.entityType === 'GOVERNMENT'
      ? []
      : countries.filter(
          (country) => !['US', 'CU', 'IR', 'KP', 'SY'].includes(country.value)
        );
  options.unshift({ value: 'US', label: 'United States' });
  const verticalOptions = verticalTypes?.map((item) => {
    return { label: item.label, value: item.value };
  });
  const stockExchangeOptions = stockExchanges?.map((item) => {
    return { label: item.label, value: item.value };
  });

  const handleEntityTypeChange = (entityType: string) => {
    handleChange(entityType, 'entityType');
    if (entityType === 'GOVERNMENT') {
      handleChange('US', 'country');
    }
  };

  const hasOpenNotice =
    localStorage.getItem(OPEN_CANADIAN_BRAND_NOTICE) ?? false;
  const [openNotice, setOpenNotice] = useState(false);
  const handleTaxNumberNotice = (country: string) => {
    if (country === 'CA' && !hasOpenNotice) {
      localStorage.setItem(OPEN_CANADIAN_BRAND_NOTICE, 'true');
      setOpenNotice(true);
    }
  };

  return (
    <>
      {brand.entityType === 'SOLE_PROPRIETOR' ? (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsDisplayNameField"
              >
                <TextField
                  error={error.displayName ? true : false}
                  value={brand.displayName}
                  id="displayName"
                  required={true}
                  fullWidth={true}
                  label="Brand Name or DBA"
                  autoComplete="off"
                  inputProps={{
                    'aria-label': 'companyName',
                    'data-testid': 'editBrandCompanyDetailsDisplayNameInput',
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  onChange={(e) => handleChange(e.target.value, 'displayName')}
                  onBlur={(event) =>
                    handleError(event.target.value, 'displayName')
                  }
                />
                {error.displayName ? (
                  <h6 className="error-msg">{error.displayName}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsVerticalTypeField"
              >
                <IntegrationReactSelect
                  disabled={false}
                  required={false}
                  options={verticalOptions}
                  label="Vertical Type"
                  error={error.vertical ? true : false}
                  value={verticalOptions.find(
                    (option) => option.value === brand.vertical
                  )}
                  handleChange={(
                    selectedOption: SelectItem,
                    keyName: KeyOfBrand
                  ) => handleChange(selectedOption.value, keyName)}
                  keyName="vertical"
                  testid="editBrandCompanyDetailsVerticalTypeSelect"
                />
                {error.vertical ? (
                  <h6 className="error-msg">{error.vertical}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsEntityTypeField"
              >
                <FormControl disabled required style={{ width: '100%' }}>
                  <InputLabel>
                    What type of legal form is the organization?
                  </InputLabel>
                  <Select
                    error={error.entityType ? true : false}
                    value={brand.entityType}
                    inputProps={{
                      'data-testid': 'editBrandCompanyDetailsEntityTypeSelect',
                    }}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      handleEntityTypeChange(event.target.value as string)
                    }
                    fullWidth={true}
                    required
                  >
                    {ENTITY_TYPES.map((entity) => (
                      <MenuItem value={entity.value} key={entity.value}>
                        {entity.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {error.entityType ? (
                  <h6 className="error-msg">{error.entityType}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsCountryField"
              >
                <IntegrationReactSelect
                  options={options}
                  label="Country of Registration"
                  value={options.find(
                    (option) => option.value === brand.country
                  )}
                  handleChange={(
                    selectedOption: SelectItem,
                    keyName: KeyOfBrand
                  ) => handleChange(selectedOption.value, keyName)}
                  keyName="country"
                  testid="editBrandCompanyDetailsCountrySelect"
                />
                {error.country ? (
                  <h6 className="error-msg">{error.country}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsStreetField"
              >
                <TextField
                  error={error.street ? true : false}
                  value={brand.street}
                  id="street"
                  required={true}
                  fullWidth={true}
                  label="Address/Street"
                  inputProps={{
                    'aria-label': 'street',
                    'data-testid': 'editBrandCompanyDetailsStreetInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'street')
                  }
                  onBlur={(event) => handleError(event.target.value, 'street')}
                />
                {error.street ? (
                  <h6 className="error-msg">{error.street}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsCityField"
              >
                <TextField
                  error={error.city ? true : false}
                  value={brand.city}
                  id="city"
                  required={true}
                  fullWidth={true}
                  label="City"
                  inputProps={{
                    'aria-label': 'city',
                    'data-testid': 'editBrandCompanyDetailsCityInput',
                  }}
                  onChange={(event) => handleChange(event.target.value, 'city')}
                  onBlur={(event) => handleError(event.target.value, 'city')}
                />
                {error.city ? (
                  <h6 className="error-msg">{error.city}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsStateField"
              >
                {brand.country === 'US' ? (
                  <IntegrationReactSelect
                    options={US_STATE_LIST}
                    label="State"
                    handleChange={(
                      selectedOption: SelectItem,
                      keyName: KeyOfBrand
                    ) => handleChange(selectedOption.value, keyName)}
                    keyName="state"
                    error={error.state ? true : false}
                    value={US_STATE_LIST.find(
                      (option) => option.value === brand.state
                    )}
                    testid="editBrandCompanyDetailsStateSelect"
                  />
                ) : (
                  <TextField
                    error={error.state ? true : false}
                    value={brand.state}
                    id="state"
                    required={true}
                    fullWidth={true}
                    label="State / Region"
                    inputProps={{
                      'aria-label': 'state',
                      'data-testid': 'editBrandCompanyDetailsStateInput',
                    }}
                    onChange={(event) =>
                      handleChange(event.target.value, 'state')
                    }
                    onBlur={(event) => handleError(event.target.value, 'state')}
                  />
                )}
                {error.state ? (
                  <h6 className="error-msg">{error.state}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsPostalCodeField"
              >
                <TextField
                  error={error.postalCode ? true : false}
                  value={brand.postalCode}
                  id="postalCode"
                  fullWidth={true}
                  required={true}
                  label={
                    brand.country === 'US'
                      ? 'ZIP Code'
                      : brand.country
                      ? 'Postal Code'
                      : 'Postal Code/ZIP Code'
                  }
                  inputProps={{
                    'aria-label': 'postalCode',
                    'data-testid': 'editBrandCompanyDetailsPostalCodeInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'postalCode')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'postalCode')
                  }
                />
                {error.postalCode ? (
                  <h6 className="error-msg">{`${error.postalCode} ${
                    brand.country === 'US' ? 'ZIP code' : 'Postal Code'
                  }`}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsWebsiteField"
              >
                <TextField
                  error={error.website ? true : false}
                  value={brand.website ?? ''}
                  id="website"
                  required={false}
                  fullWidth={true}
                  label="Website/Online Presence"
                  inputProps={{
                    'aria-label': 'website',
                    'data-testid': 'editBrandCompanyDetailsWebsiteInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'website')
                  }
                  onBlur={(event) => handleError(event.target.value, 'website')}
                />
                {error.website ? (
                  <h6 className="error-msg">{error.website}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsReferenceIdField"
              >
                <TextField
                  inputProps={{
                    'aria-label': 'referenceId',
                    'data-testid': 'editBrandCompanyDetailsReferenceIdInput',
                  }}
                  id="referenceId"
                  label="Reference ID"
                  fullWidth
                  required={brand.entityType === 'SOLE_PROPRIETOR'}
                  error={!!error.referenceId}
                  value={brand.referenceId ?? ''}
                  onChange={(event) =>
                    handleChange(event.target.value, 'referenceId')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'referenceId')
                  }
                />
                {error.referenceId ? (
                  <h6 className="error-msg">{error.referenceId}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsFirstNameField"
              >
                <TextField
                  error={error.firstName ? true : false}
                  value={brand.firstName ?? ''}
                  id="firstName"
                  required={true}
                  fullWidth={true}
                  label="First Name"
                  inputProps={{
                    'aria-label': 'firstName',
                    'data-testid': 'editBrandCompanyDetailsFirstNameInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'firstName')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'firstName')
                  }
                />
                {error.firstName ? (
                  <h6 className="error-msg">{error.firstName}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsLastNameField"
              >
                <TextField
                  error={error.lastName ? true : false}
                  value={brand.lastName ?? ''}
                  id="lastName"
                  required={true}
                  fullWidth={true}
                  label="Last Name"
                  inputProps={{
                    'aria-label': 'lastName',
                    'data-testid': 'editBrandCompanyDetailsLastNameInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'lastName')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'lastName')
                  }
                />
                {error.lastName ? (
                  <h6 className="error-msg">{error.lastName}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsMobilePhoneField"
              >
                <TextField
                  inputProps={{
                    'aria-label': 'mobilePhone',
                    'data-testid': 'editBrandCompanyDetailsMobilePhoneInput',
                  }}
                  id="mobilePhone"
                  label="Mobile Phone"
                  required
                  fullWidth
                  error={!!error.mobilePhone}
                  value={brand.mobilePhone ?? ''}
                  onChange={(event) =>
                    handleChange(event.target.value, 'mobilePhone')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'mobilePhone')
                  }
                />
                <ToolTip title="This field is required for Sole Proprietor brands and will be used to perform OTP which will allow your brand to be moved to Verified status." />
                {error.mobilePhone ? (
                  <h6 className="error-msg">{error.mobilePhone}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsDisplayNameField"
              >
                <TextField
                  error={error.displayName ? true : false}
                  value={brand.displayName}
                  id="displayName"
                  required={true}
                  fullWidth={true}
                  label="Brand Name or DBA"
                  autoComplete="off"
                  inputProps={{
                    'aria-label': 'companyName',
                    'data-testid': 'editBrandCompanyDetailsDisplayNameInput',
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  onChange={(e) =>
                    handleChange(e.target.value as string, 'displayName')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'displayName')
                  }
                />
                {error.displayName ? (
                  <h6 className="error-msg">{error.displayName}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsVerticalTypeField"
              >
                <IntegrationReactSelect
                  disabled={false}
                  required={true}
                  options={verticalOptions}
                  label="Vertical Type"
                  error={error.vertical ? true : false}
                  value={
                    brand.vertical
                      ? verticalOptions.find(
                          (option) => option.value === brand.vertical
                        )
                      : ''
                  }
                  handleChange={(
                    selectedOption: SelectItem,
                    keyName: KeyOfBrand
                  ) => handleChange(selectedOption.value, keyName)}
                  keyName="vertical"
                  testid="editBrandCompanyDetailsVerticalTypeSelect"
                />
                {error.vertical ? (
                  <h6 className="error-msg">{error.vertical}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsEntityTypeField"
              >
                <FormControl required style={{ width: '100%' }}>
                  <InputLabel>
                    What type of legal form is the organization?
                  </InputLabel>
                  <Select
                    error={error.entityType ? true : false}
                    value={brand.entityType}
                    inputProps={{
                      'data-testid': 'editBrandCompanyDetailsEntityTypeSelect',
                    }}
                    onChange={(event) =>
                      handleEntityTypeChange(event.target.value as string)
                    }
                    fullWidth={true}
                    required
                  >
                    {ENTITY_TYPES.map(
                      (entity) =>
                        entity.value !== 'SOLE_PROPRIETOR' && (
                          <MenuItem value={entity.value} key={entity.value}>
                            {entity.label}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
                {error.entityType ? (
                  <h6 className="error-msg">{error.entityType}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsCountryField"
              >
                <IntegrationReactSelect
                  options={options}
                  label="Country of Registration"
                  value={options.find(
                    (option) => option.value === brand.country
                  )}
                  handleChange={(
                    selectedOption: SelectItem,
                    keyName: KeyOfBrand
                  ) => handleChange(selectedOption.value, keyName)}
                  keyName="country"
                  testid="editBrandCompanyDetailsCountrySelect"
                />
                {error.country ? (
                  <h6 className="error-msg">{error.country}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsEinField"
              >
                <TextField
                  error={error.ein ? true : false}
                  value={brand.ein ?? ''}
                  id="ein"
                  required={
                    brand.entityType === 'SOLE_PROPRIETOR' ? false : true
                  }
                  fullWidth={true}
                  label={
                    brand.einIssuingCountry === 'US'
                      ? 'EIN'
                      : brand.einIssuingCountry
                      ? 'Tax Number/ID'
                      : 'Tax Number/ID/EIN'
                  }
                  inputProps={{
                    'aria-label': 'ein',
                    'data-testid': 'editBrandCompanyDetailsEinInput',
                  }}
                  onFocus={() => handleTaxNumberNotice(brand.einIssuingCountry)}
                  onChange={(event) =>
                    handleChange(event.target.value as string, 'ein')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value as string, 'ein')
                  }
                />
                <ToolTip title="Changes to the EIN will not impact the verification status of the Brand until resubmission. Only non-vetted Brands can edit EIN data. For further information please contact support@campaignregistry.com." />
                {error.ein ? <h6 className="error-msg">{error.ein}</h6> : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsEinIssuingCountryField"
              >
                <IntegrationReactSelect
                  options={options}
                  required={true}
                  label={
                    brand.einIssuingCountry === 'US'
                      ? 'EIN Issuing Country'
                      : brand.einIssuingCountry
                      ? 'Tax Number/ID Issuing Country'
                      : 'Tax Number/ID/EIN Issuing Country'
                  }
                  value={options.find(
                    (option) => option.value === brand.einIssuingCountry
                  )}
                  handleChange={(
                    selectedOption: SelectItem,
                    keyName: KeyOfBrand
                  ) => handleChange(selectedOption.value, keyName)}
                  keyName="einIssuingCountry"
                  error={error.einIssuingCountry ? true : false}
                  testid="editBrandCompanyDetailsEinIssuingCountrySelect"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsAltBusinessIdTypeField"
              >
                <FormControl style={{ width: '100%' }}>
                  <InputLabel>{`DUNS or GIIN or LEI Number`}</InputLabel>
                  <Select
                    inputProps={{
                      'data-testid':
                        'editBrandCompanyDetailsAltBusinessIdTypeSelect',
                    }}
                    error={error.altBusinessIdType ? true : false}
                    value={brand.altBusinessIdType ?? ''}
                    onChange={(event) =>
                      handleChange(
                        event.target.value as string,
                        'altBusinessIdType'
                      )
                    }
                    fullWidth={true}
                  >
                    <MenuItem value={'DUNS'}>DUNS</MenuItem>
                    <MenuItem value={'GIIN'}>GIIN</MenuItem>
                    <MenuItem value={'LEI'}>LEI</MenuItem>
                  </Select>
                </FormControl>
                <ToolTip
                  title={`Please select an "Alternative Business ID". You can provide the DUNS Number, GIIN, or LEI. This is a complementary and optional field to help better identify the Brand. The "DUNS Number" in particular is important in identifying non-US Brands.`}
                />
                {error.altBusinessIdType ? (
                  <h6 className="error-msg">{error.altBusinessIdType}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsAltBusinessIdField"
              >
                <TextField
                  error={error.altBusinessId ? true : false}
                  value={brand.altBusinessId ?? ''}
                  id="altBusinessId"
                  disabled={brand.altBusinessIdType ? false : true}
                  fullWidth={true}
                  label={
                    brand.altBusinessIdType
                      ? `${brand.altBusinessIdType} Number`
                      : 'DUNS or GIIN or LEI Number'
                  }
                  inputProps={{
                    'aria-label': 'altBusinessId',
                    'data-testid': 'editBrandCompanyDetailsAltBusinessIdInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'altBusinessId')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'altBusinessId')
                  }
                />
                <ToolTip
                  title={`Please enter the "Alternative Business ID". This a a complementary and optional field to hep better identify the Brand. `}
                />
                {error.altBusinessId ? (
                  <h6 className="error-msg">{error.altBusinessId}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsStreetField"
              >
                <TextField
                  error={error.street ? true : false}
                  value={brand.street}
                  id="street"
                  required={true}
                  fullWidth={true}
                  label="Address/Street"
                  inputProps={{
                    'aria-label': 'street',
                    'data-testid': 'editBrandCompanyDetailsStreetInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'street')
                  }
                  onBlur={(event) => handleError(event.target.value, 'street')}
                />
                {error.street ? (
                  <h6 className="error-msg">{error.street}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsCityField"
              >
                <TextField
                  error={error.city ? true : false}
                  value={brand.city}
                  id="city"
                  required={true}
                  fullWidth={true}
                  label="City"
                  inputProps={{
                    'aria-label': 'city',
                    'data-testid': 'editBrandCompanyDetailsCityInput',
                  }}
                  onChange={(event) => handleChange(event.target.value, 'city')}
                  onBlur={(event) => handleError(event.target.value, 'city')}
                />
                {error.city ? (
                  <h6 className="error-msg">{error.city}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsStateField"
              >
                {brand.country === 'US' ? (
                  <IntegrationReactSelect
                    options={usStates}
                    label="State"
                    handleChange={(
                      selectedOption: SelectItem,
                      keyName: KeyOfBrand
                    ) => handleChange(selectedOption.value, keyName)}
                    keyName="state"
                    error={error.state ? true : false}
                    value={usStates.find(
                      (option) => option.value === brand.state
                    )}
                    testid="editBrandCompanyDetailsStateSelect"
                  />
                ) : (
                  <TextField
                    error={error.state ? true : false}
                    value={brand.state}
                    id="state"
                    required={true}
                    fullWidth={true}
                    label="State / Region"
                    inputProps={{
                      'aria-label': 'state',
                      'data-testid': 'editBrandCompanyDetailsStateInput',
                    }}
                    onChange={(event) =>
                      handleChange(event.target.value, 'state')
                    }
                    onBlur={(event) => handleError(event.target.value, 'state')}
                  />
                )}
                {error.state ? (
                  <h6 className="error-msg">{error.state}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsPostalCodeField"
              >
                <TextField
                  error={error.postalCode ? true : false}
                  value={brand.postalCode}
                  id="postalCode"
                  fullWidth={true}
                  required={true}
                  label={
                    brand.country === 'US'
                      ? 'ZIP Code'
                      : brand.country
                      ? 'Postal Code'
                      : 'Postal Code/ZIP Code'
                  }
                  inputProps={{
                    'aria-label': 'postalCode',
                    'data-testid': 'editBrandCompanyDetailsPostalCodeInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'postalCode')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'postalCode')
                  }
                />
                {error.postalCode ? (
                  <h6 className="error-msg">{`${error.postalCode} ${
                    brand.country === 'US' ? 'ZIP code' : 'Postal Code'
                  }`}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsStockSymbolField"
              >
                <TextField
                  error={error.stockSymbol ? true : false}
                  value={brand.stockSymbol ?? ''}
                  id="stockSymbol"
                  required={brand.entityType === 'PUBLIC_PROFIT' ? true : false}
                  disabled={brand.entityType === 'PUBLIC_PROFIT' ? false : true}
                  fullWidth={true}
                  label="Stock Symbol"
                  inputProps={{
                    'aria-label': 'stockSymbol',
                    'data-testid': 'editBrandCompanyDetailsStockSymbolInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'stockSymbol')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'stockSymbol')
                  }
                />
                {error.stockSymbol ? (
                  <h6 className="error-msg">{error.stockSymbol}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsStockExchangeField"
              >
                <IntegrationReactSelect
                  disabled={brand.entityType === 'PUBLIC_PROFIT' ? false : true}
                  options={stockExchangeOptions}
                  label="Stock Exchange"
                  error={error.stockExchange ? true : false}
                  value={
                    brand.stockExchange
                      ? stockExchangeOptions.find(
                          (option) => option.value === brand.stockExchange
                        )
                      : ''
                  }
                  handleChange={(
                    selectedOption: SelectItem,
                    keyName: KeyOfBrand
                  ) => handleChange(selectedOption.value, keyName)}
                  keyName="stockExchange"
                  testid="editBrandCompanyDetailsStockExchangeSelect"
                />
                {error.stockExchange ? (
                  <h6 className="error-msg">{error.stockExchange}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsWebsiteField"
              >
                <TextField
                  error={error.website ? true : false}
                  value={brand.website ?? ''}
                  id="website"
                  required={false}
                  fullWidth={true}
                  label="Website/Online Presence"
                  inputProps={{
                    'aria-label': 'website',
                    'data-testid': 'editBrandCompanyDetailsWebsiteInput',
                  }}
                  onChange={(event) =>
                    handleChange(event.target.value, 'website')
                  }
                  onBlur={(event) => handleError(event.target.value, 'website')}
                />
                {error.website ? (
                  <h6 className="error-msg">{error.website}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="editBrandCompanyDetailsReferenceIdField"
              >
                <TextField
                  inputProps={{
                    'aria-label': 'referenceId',
                    'data-testid': 'editBrandCompanyDetailsReferenceIdInput',
                  }}
                  id="referenceId"
                  label="Reference ID"
                  fullWidth
                  error={!!error.referenceId}
                  value={brand.referenceId ?? ''}
                  onChange={(event) =>
                    handleChange(event.target.value, 'referenceId')
                  }
                  onBlur={(event) =>
                    handleError(event.target.value, 'referenceId')
                  }
                />
                <ToolTip title="The unique number or code used to reference your client inside your organization. This information is mandatory when selecting a Sole Proprietor entity type." />
                {error.referenceId ? (
                  <h6 className="error-msg">{error.referenceId}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* Edit Contact Detail */}

      <div className="title contact">
        <Box flexDirection="row" alignItems="center" margin={{ bottom: 'xs' }}>
          <Box margin={{ right: 'xs' }}>
            <FontAwesomeIcon icon={faUserCircle} size="xl" />
          </Box>
          <h3 className="heading1">Contact Details</h3>
        </Box>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div
            className="form-group-field"
            data-testid="editBrandCompanyDetailsEmailField"
          >
            <TextField
              error={error.email ? true : false}
              value={brand.email}
              id="email"
              required
              fullWidth={true}
              label="Support Email Address"
              inputProps={{
                'aria-label': 'email',
                'data-testid': 'editBrandCompanyDetailsEmailInput',
              }}
              onChange={(event) => handleChange(event.target.value, 'email')}
              onBlur={(event) => handleError(event.target.value, 'email')}
            />
            {error.email ? (
              <h6 className="error-msg">{`${error.email} Support Email Address`}</h6>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            className="form-group-field"
            data-testid="editBrandCompanyDetailsPhoneField"
          >
            <PhoneInput
              flags={flags}
              defaultCountry={brand.country as Country}
              value={brand.phone as Value}
              countryOptionsOrder={['US', 'IT', 'IN', '|']}
              onChange={(telNumber) =>
                handleChange(telNumber as string, 'phone')
              }
              limitMaxLength
              placeholder="Support Phone Number"
              onCountryChange={(countryCode) => setCountryIsoCode(countryCode)}
              onBlur={() => handleError(brand.phone, 'phone')}
              className={error.phone ? 'error' : ''}
              data-testid="editBrandCompanyDetailsPhoneInput"
            />
            {error.phone ? <h6 className="error-msg">{error.phone}</h6> : null}
          </div>
        </Grid>
      </Grid>
      <CanadianBrandNoticeModal
        open={openNotice}
        handleClose={() => setOpenNotice(false)}
      />

      {/* Edit Brand Relationship */}

      <div className="title contact">
        <Box flexDirection="row" alignItems="center" margin={{ bottom: 'xs' }}>
          <Box margin={{ right: 'xs' }}>
            <FontAwesomeIcon icon={faChartNetwork} size="xl" />
          </Box>
          <h3 className="heading1">Brand Relationship</h3>
        </Box>
      </div>
      <Grid
        container
        justifyContent="space-between"
        className="brand-relationship-wrapper"
        data-testid="editBrandCompanyDetailsBrandRelationshipWrapper"
      >
        {BRAND_RELATIONSHIP_SCORE.map((item) => (
          <Grid
            item
            xs={2}
            key={item.value}
            className={`relationship-item ${
              Number(brand.brandRelationshipScore) === item.value
                ? ' active'
                : brand.entityType === 'SOLE_PROPRIETOR'
                ? 'disabledOption'
                : ''
            }`}
            onClick={() => handleChange(item.value, 'brandRelationshipScore')}
            data-testid={`editBrandCompanyDetailsBrandRelationshipItem`}
          >
            <img src={item.img} alt="" />
            <div className="top-blk">
              <p className="paragraph title">{item.label}</p>
            </div>
            <div className="content-blk">
              <p className="paragraph">{item.description}</p>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default EditBrandCompanyDetails;
