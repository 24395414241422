import { AttachmentFolder } from './enums';
import { Attachment, FileFormats } from './types';
import {
  globalDeleteService,
  globalGetService,
  globalPostService,
  globalPutService,
} from '../../../utils/globalApiServices';

export const getSupportedFileFormats = async (folder: AttachmentFolder) => {
  const response = await globalGetService(
    `csp/attachments/campaignFolder/${folder}/supportedFileFormats`
  );
  if (response.ok) {
    return response.data as FileFormats;
  }
};

export const uploadAttachment = async (
  folder: AttachmentFolder,
  file: File
) => {
  const data = new FormData();
  data.append('folderType', folder);
  data.append('file', file);
  const response = await globalPostService('csp/attachments', data);
  if (response.ok) {
    return response.data as Attachment;
  }
};

export const uploadAttachmentForCampaign = async (
  campaignUid: string,
  folder: AttachmentFolder,
  file: File
) => {
  const data = new FormData();
  data.append('file', file);
  const response = await globalPostService(
    `csp/attachments/campaign/${campaignUid}/folder/${folder}`,
    data
  );
  if (response.ok) {
    return response.data as Attachment;
  }
};

export const getAttachmentsForCampaign = async (
  campaignUid: string,
  folder: AttachmentFolder
) => {
  const response = await globalGetService(
    `csp/attachments/campaign/${campaignUid}?folder=${encodeURIComponent(
      folder
    )}`
  );
  if (response.ok) {
    return response.data as Attachment[];
  }
};

export const getAttachment = async (attachmentUuid: string) => {
  const response = await globalGetService(
    `csp/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'arraybuffer'
  );
  if (response.ok) {
    return response.data as ArrayBuffer;
  }
};

export const deleteAttachment = async (attachmentUuid: string) => {
  const response = await globalDeleteService(
    `csp/attachments/delete?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`
  );
  if (response.ok) {
    return attachmentUuid;
  }
};

export const updateCampaign = async (
  campaignUid: string,
  data: Record<string, any>
) => {
  const response = await globalPutService(`csp/campaigns/${campaignUid}`, data);
  if (response.ok) {
    return response.data;
  }
};
