import regexExpressions from '../../constants/regexExpressions';

export const CSPErrorCode = {
  companyName: {
    0: '',
    1: 'Please enter Legal Company Name',
    3: 'Maximum 255 chars are allowed',
    4: 'Invalid input',
  },
  companyNameObj: {
    requiredFlag: true,
    maxLength: 255,
    regexPattern: regexExpressions.alphaNumeric,
  },
  displayName: {
    0: '',
    1: 'Please enter Brand Name or DBA',
    3: 'Maximum 255 chars are allowed',
    4: 'Invalid input',
  },
  displayNameObj: {
    requiredFlag: true,
    maxLength: 255,
    regexPattern: regexExpressions.alphaNumeric,
  },
  ein: {
    0: '',
    1: 'Please enter ',
    4: 'Invalid ',
  },
  einObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.ein,
  },
  street: {
    0: '',
    1: 'Please enter Address/Street',
    3: 'Maximum 255 chars are allowed',
    4: 'Invalid input',
  },
  streetObj: {
    requiredFlag: true,
    maxLength: 255,
    regexPattern: regexExpressions.alphaNumeric,
  },
  city: {
    0: '',
    1: 'Please enter City',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid input',
  },
  cityObj: {
    requiredFlag: true,
    maxLength: 100,
    regexPattern: regexExpressions.alphaNumeric,
  },
  postalCode: {
    0: '',
    1: 'Please enter',
    3: 'Maximum 20 chars allowed for',
    4: 'Invalid',
  },
  postalCodeObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.zip,
    maxLength: 20,
  },
  state: {
    0: '',
    1: 'Please enter State',
    3: 'Maximum 50 chars allowed',
    4: 'Invalid input',
  },
  stateObj: {
    maxLength: 50,
    requiredFlag: true,
    regexPattern: regexExpressions.alphaNumeric,
  },
  website: {
    0: '',
    1: 'Please enter Website',
    3: 'Maximum 255 chars are allowed',
    4: 'Invalid Website',
  },
  websiteObj: {
    requiredFlag: true,
    maxLength: 255,
    regexPattern: regexExpressions.url,
  },
  stockSymbol: {
    0: '',
    1: 'Please enter Stock Symbol',
    3: 'Maximum 10 chars allowed',
    4: 'Invalid Stock Symbol',
  },
  stockSymbolObj: {
    requiredFlag: true,
    // regexPattern: regexExpressions.alphabets,
    maxLength: 10,
  },
  firstName: {
    0: '',
    1: 'Please enter First Name',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid First Name',
  },
  firstNameObj: {
    requiredFlag: true,
    maxLength: 100,
    regexPattern: regexExpressions.name,
  },
  lastName: {
    0: '',
    1: 'Please enter Last Name',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid Last Name',
  },
  lastNameObj: {
    requiredFlag: true,
    maxLength: 100,
    regexPattern: regexExpressions.name,
  },
  mobilePhone: {
    0: '',
    1: 'Please enter Mobile Phone',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid Mobile Phone',
    5: 'Invalid input',
  },
  mobilePhoneObj: {
    requiredFlag: true,
    maxLength: 100,
    xssProtection: true,
  },
  email: {
    0: '',
    1: 'Please enter ',
    4: 'Invalid ',
    3: 'Maximum 100 chars are allowed',
  },
  emailObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.email,
    maxLength: 100,
  },
  refDcaContactName: {
    0: '',
    1: 'Please enter Contact Name',
    4: 'Invalid input',
  },
  refDcaContactNameObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.alphaNumeric,
  },
  refDcaContactEmail: {
    0: '',
    1: 'Please enter Email Address',
    4: 'Email Address is invalid',
  },
  refDcaContactEmailObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.email,
  },
  refDcaName: {
    0: '',
    1: 'Please enter Name of Direct Connect Aggregator (DCA)',
    4: 'Invalid input',
  },
  refDcaNameObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.alphaNumeric,
  },
  explanation: {
    0: '',
    1: 'Please enter Explanation',
    4: 'Invalid input',
  },
  explanationObj: {
    requiredFlag: true,
    regexPattern: regexExpressions.alphaNumeric,
  },
  evpVettingId: {
    0: '',
    1: 'Please enter Vetting Identifier',
  },
  evpVettingIdObj: {
    requiredFlag: true,
  },
  stockExchange: {
    0: '',
    1: 'Please select Stock Exchange',
  },
  stockExchangeObj: {
    requiredFlag: true,
  },
  vertical: {
    0: '',
    1: 'Please select Vertical',
  },
  verticalObj: {
    requiredFlag: true,
  },
  techEmail: {
    0: '',
    1: 'Please enter Technical / Support Email Address',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid Technical / Support Email Address',
  },
  techEmailObj: {
    requiredFlag: true,
    maxLength: 100,
    regexPattern: regexExpressions.email,
  },
  financeEmail: {
    0: '',
    1: 'Please enter Finance / Billing Email Address',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid Finance / Billing Email Address',
  },
  financeEmailObj: {
    requiredFlag: true,
    maxLength: 100,
    regexPattern: regexExpressions.email,
  },
  altBusinessId: {
    0: '',
    3: 'Maximum 50 chars are allowed',
  },
  altBusinessIdObj: {
    maxLength: 50,
  },
  referenceId: {
    0: '',
    1: 'Please enter Reference ID',
    3: 'Maximum 50 chars are allowed',
    5: 'Invalid input',
  },
  referenceIdObj: {
    requiredFlag: true,
    maxLength: 50,
    xssProtection: true,
  },
  pinSms: {
    0: '',
    1: 'Unknown Error',
    2: 'Unknown Error',
    3: 'Maximum 320 chars are allowed',
    4: 'Unknown Error',
    5: 'Invalid input',
  },
  pinSmsObj: {
    maxLength: 320,
    xssProtection: true,
  },
  otpPin: {
    0: '',
    1: 'Unknown Error',
    2: 'Unknown Error',
    3: 'Maximum 6 chars are allowed',
    4: 'Unknown Error',
    5: 'Invalid input',
  },
  otpPinObj: {
    maxLength: 6,
    xssProtection: true,
  },
  escalateEmail: {
    0: '',
    1: 'Please enter System Notification Email Address',
    3: 'Maximum 100 chars are allowed',
    4: 'Invalid Email Address',
  },
  escalateEmailObj: {
    requiredFlag: true,
    maxLength: 100,
    regexPattern: regexExpressions.email,
  },
};

export const BrandErrorCode = {
  ...CSPErrorCode,
  website: {
    0: '',
    3: 'Maximum 255 chars are allowed',
  },
  websiteObj: {
    maxLength: 255,
  },
  mobilePhone: {
    0: '',
    1: 'Please enter Mobile Phone',
    3: 'Maximum 20 chars are allowed',
    4: 'Invalid Mobile Phone',
    5: 'Invalid input',
  },
  mobilePhoneObj: {
    requiredFlag: true,
    maxLength: 20,
    xssProtection: true,
  },
};

export const CampaignFilterErrorCode = {
  referenceId: {
    0: '',
    5: 'Invalid input',
  },
  referenceIdObj: {
    xssProtection: true,
  },
};

export const BrandFilterErrorCode = {
  ...CampaignFilterErrorCode,
};

export const CSP_Info = {
  companyName: '',
  displayName: '',
  country: {},
  entityType: '',
  ein: '',
  street: '',
  city: '',
  postalCode: '',
  state: '',
  website: '',
  stockSymbol: '',
  stockExchange: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  techEmail: '',
  financeEmail: '',
  escalateEmail: '',
  questionnairePrimaryCnpName: '',
  questionnaireSecondaryCnpName: '',
  questionnaireRelationshipLength: '',
};

export const BRAND_Info = {
  companyName: '',
  displayName: '',
  country: {},
  entityType: '',
  ein: '',
  einIssuingCountry: {},
  street: '',
  city: '',
  postalCode: '',
  state: '',
  website: '',
  stockSymbol: '',
  stockExchange: '',
  vertical: '',
  brandRelationshipScore: '',
  email: '',
  phone: '',
  altBusinessIdType: '',
  altBusinessId: '',
};
export const campaignErrorCode = {
  usecase: {
    0: '',
    1: 'Please select Use Case type',
  },
  usecaseObj: {
    requiredFlag: true,
  },
  vertical: {
    0: '',
    1: 'Please select Vertical',
  },
  verticalObj: {
    requiredFlag: true,
  },
  description: {
    0: '',
    1: 'Please enter Campaign Description',
    2: 'Minimum 40 chars',
    3: 'Maximum 4096 chars are allowed',
    4: 'Invalid input',
    5: 'Invalid input',
  },
  descriptionObj: {
    requiredFlag: true,
    maxLength: 4096,
    minLength: 40,
    regexPattern: regexExpressions.alphaNumeric,
    xssProtection: true,
  },
  messageFlow: {
    0: '',
    1: 'Please enter Call-to-Action / Message Flow',
    2: 'Minimum 40 chars',
    3: 'Maximum 4096 chars are allowed',
    4: 'Invalid input',
    5: 'Invalid input',
  },
  messageFlowObj: {
    requiredFlag: true,
    maxLength: 4096,
    minLength: 40,
    regexPattern: regexExpressions.alphaNumeric,
    xssProtection: true,
  },
  termsAndConditionsLink: {
    0: '',
    3: 'Maximum 255 chars are allowed',
    4: 'Invalid input',
    5: 'Invalid input',
  },
  termsAndConditionsLinkObj: {
    maxLength: 255,
    regexPattern: regexExpressions.alphaNumeric,
    xssProtection: true,
  },
  privacyPolicyLink: {
    0: '',
    3: 'Maximum 255 chars are allowed',
    4: 'Invalid input',
    5: 'Invalid input',
  },
  privacyPolicyLinkObj: {
    maxLength: 255,
    regexPattern: regexExpressions.alphaNumeric,
    xssProtection: true,
  },
  sample1: {
    0: '',
    1: 'Please type any Sample message',
    2: 'Minimum 20 chars',
    3: 'Maximum 1024 chars are allowed',
    5: 'Invalid input',
  },
  sample1Obj: {
    requiredFlag: true,
    maxLength: 1024,
    minLength: 20,
    xssProtection: true,
  },
  sample2: {
    0: '',
    1: 'Please type any Sample message',
    2: 'Minimum 20 chars',
    3: 'Maximum 1024 chars are allowed',
    5: 'Invalid input',
  },
  sample2Obj: {
    requiredFlag: true,
    maxLength: 1024,
    minLength: 20,
    xssProtection: true,
  },
  sample3: {
    0: '',
    1: 'Please type any Sample message',
    2: 'Minimum 20 chars',
    3: 'Maximum 1024 chars are allowed',
    5: 'Invalid input',
  },
  sample3Obj: {
    requiredFlag: true,
    maxLength: 1024,
    minLength: 20,
    xssProtection: true,
  },
  sample4: {
    0: '',
    1: 'Please type any Sample message',
    2: 'Minimum 20 chars',
    3: 'Maximum 1024 chars are allowed',
    5: 'Invalid input',
  },
  sample4Obj: {
    requiredFlag: true,
    maxLength: 1024,
    minLength: 20,
    xssProtection: true,
  },
  sample5: {
    0: '',
    1: 'Please type any Sample message',
    2: 'Minimum 20 chars',
    3: 'Maximum 1024 chars are allowed',
    5: 'Invalid input',
  },
  sample5Obj: {
    requiredFlag: true,
    maxLength: 1024,
    minLength: 20,
    xssProtection: true,
  },
  primaryDcaUid: {
    0: '',
    1: 'Please select your Primary DCA',
  },
  primaryDcaUidObj: {
    requiredFlag: true,
  },
  upstreamCnpUid: {
    0: '',
    1: 'Please select your Connectivity Partner',
  },
  upstreamCnpUidObj: {
    requiredFlag: true,
  },
  brandUid: {
    0: '',
    1: 'Please select Brand',
  },
  brandUidObj: {
    requiredFlag: true,
  },
  campaignRejectionDescription: {
    0: '',
    1: 'Please enter Explanation',
    3: 'Maximum 2048 chars are allowed',
    4: 'Invalid input',
  },
  campaignRejectionDescriptionObj: {
    requiredFlag: true,
    maxLength: 2048,
    regexPattern: regexExpressions.alphaNumeric,
  },
  optinMessage: {
    0: '',
    2: 'Minimum 20 chars',
    3: 'Maximum 320 chars are allowed',
    5: 'Invalid input',
  },
  optinMessageObj: {
    minLength: 20,
    maxLength: 320,
    xssProtection: true,
  },
  optoutMessage: {
    0: '',
    1: 'Please enter message',
    2: 'Minimum 20 chars',
    3: 'Maximum 320 chars are allowed',
    5: 'Invalid input',
  },
  optoutMessageObj: {
    requiredFlag: true,
    minLength: 20,
    maxLength: 320,
    xssProtection: true,
  },
  helpMessage: {
    0: '',
    1: 'Please enter message',
    2: 'Minimum 20 chars',
    3: 'Maximum 320 chars are allowed',
    5: 'Invalid input',
  },
  helpMessageObj: {
    requiredFlag: true,
    minLength: 20,
    maxLength: 320,
    xssProtection: true,
  },
  embeddedLinkSample: {
    0: '',
    3: 'Maximum 255 chars are allowed',
    5: 'Invalid input',
  },
  embeddedLinkSampleObj: {
    maxLength: 255,
    xssProtection: true,
  },
};
