import {
  BoxV2 as Box,
  Button,
  DropdownV2 as Dropdown,
  Flex,
  TextInput,
} from 'portal-commons';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, makeStyles } from '@material-ui/core';
import { faCircleUser, faInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROLES, ROLE_DESCRIPTIONS } from '../constants';
import { User, UserUpdatePayload } from '../types';
import { isRequired } from '../validators';
import { CustomTooltip } from '../../../../shared_elements';

interface UserEditModalProps {
  open: boolean;
  disabled: boolean;
  user: User;
  roles: string[];
  onClose: () => void;
  onSubmit: (data: UserUpdatePayload) => void;
}

const useStyles = makeStyles({
  container: {
    width: '525px',
    padding: '24px 32px 9px',
  },
});

const UserEditModal: FunctionComponent<UserEditModalProps> = ({
  open,
  disabled,
  user,
  roles,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const { handleSubmit, control, watch, reset } = useForm<UserUpdatePayload>({
    defaultValues: {
      userAppRole: user.profile.csp_app_role?.[0] ?? ROLES.REVIEWER,
    },
  });
  const options = useMemo(
    () =>
      roles.map((role) => ({
        label: role,
        value: role,
      })),
    [roles]
  );
  const role = watch('userAppRole');

  useEffect(() => {
    if (open) {
      reset({
        userAppRole: user.profile.csp_app_role?.[0] ?? ROLES.REVIEWER,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: 'column',
            rowGap: '8px',
            color: '#333E41',
            fontFamily: 'Roboto',
          }}
          data-testid="userEditModal"
        >
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '12px',
              padding: '12px 0',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '28px',
            }}
          >
            <FontAwesomeIcon icon={faCircleUser} />
            <Box>Edit User</Box>
          </Flex>
          <Box
            sx={{
              padding: '8px 0 12px',
              textAlign: 'center',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
            }}
          >
            USER ID: <span data-testid="id">{user.id}</span>
          </Box>
          <Flex sx={{ flexDirection: 'column', rowGap: '16px' }}>
            <TextInput
              readOnly
              label="First Name"
              value={user.profile.firstName}
              data-testid="firstName"
            />
            <TextInput
              readOnly
              label="Last Name"
              value={user.profile.lastName}
              data-testid="lastName"
            />
            <TextInput
              readOnly
              label="Email"
              value={user.profile.email}
              data-testid="email"
            />
            <Flex
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                columnGap: '8px',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="userAppRole"
                  control={control}
                  rules={{
                    validate: {
                      isRequired,
                    },
                  }}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error = {} },
                  }) => (
                    <Dropdown
                      required
                      disabled={disabled}
                      error={!!error.message}
                      label="Role"
                      helperText={error.message}
                      options={options}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      data-testid="role"
                    />
                  )}
                />
              </Box>
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                }}
              >
                <CustomTooltip
                  arrow
                  placement="right"
                  title={
                    role
                      ? ROLE_DESCRIPTIONS[role]
                      : 'Select the role of the user'
                  }
                >
                  <FontAwesomeIcon icon={faInfo} color="#0091B3" />
                </CustomTooltip>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '20px',
              padding: '24px 0 40px',
            }}
          >
            <Button
              variant="outline"
              onClick={onClose}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={disabled} data-testid="saveButton">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Dialog>
  );
};

export default UserEditModal;
