import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { S3_ASSETS_PATH } from '../../../../constants';
import CheckBox from '../../../../ui_elements/CheckBox';
import ToolTip from '../../../../ui_elements/ToolTip';

const useStyles = makeStyles({
  note: {
    padding: '0 20px',
    marginTop: '14px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    color: '#0091B3',
  },
  iconContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  iconButton: {
    width: 28,
    height: 28,
    '&:hover': {
      background: 'none',
    },
  },
  iconSvg: {
    width: 20,
  },
  viewIconButton: {
    width: 28,
    height: 28,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  icon: {
    width: '14px',
    height: '8px',
  },
});

const CampaignAttributeNew = (props) => {
  const {
    additionalSetup,
    additionalSetupActive,
    setAdditionalSetupActive,
    id,
  } = props;
  const [additionalSetupTriggered, setAdditionalSetupTriggered] = useState(
    props.campaignInfo && !!props.campaignInfo[props.id]
  );
  const classes = useStyles();
  const isViewMode = props.type === 'view';

  const handleYesClick = () => {
    props.handleChange(true, props.id, props.label);
    if (additionalSetup) {
      setAdditionalSetupActive && setAdditionalSetupActive(true);
      setAdditionalSetupTriggered(true);
    }
  };

  const handleNoClick = () => {
    props.handleChange(false, props.id, props.label);
    if (additionalSetup) {
      setAdditionalSetupActive && setAdditionalSetupActive(false);
      setAdditionalSetupTriggered(false);
    }
  };

  const handleVisibilityClick = () => {
    setAdditionalSetupActive &&
      setAdditionalSetupActive(!additionalSetupActive);
  };

  return isViewMode ? (
    <Grid
      item
      xs={props.xs ? props.xs : 6}
      style={props.style}
      data-testid={`campaignAttributeNew-${id}`}
    >
      <div
        className="campaign-attribute view"
        style={{
          height: props.notes ? '100%' : 'unset',
          maxHeight: props.notes ? 'unset' : 'inherit',
        }}
      >
        <Grid
          style={{ height: props.notes ? '100%' : '50px' }}
          className="view-container"
          container
          alignItems="center"
        >
          <Grid item className="title" style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
              data-testid="campaignAttributeNewLabel"
            >
              <p className="">
                {props.label}
                <ToolTip title={props.tooltip} placement="right-end" />
              </p>
              {props.notes &&
                props.notes.map((note, index) => (
                  <Box
                    key={index}
                    className={classes.note}
                    data-testid={`campaignAttributeNewNote-${index}`}
                  >
                    {note}
                  </Box>
                ))}
            </div>
          </Grid>
          <Grid item className="buttons-view" style={{ width: 193 }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              data-testid="campaignAttributeNewValue"
            >
              <div className="view-item right">
                {props.value === true ? (
                  <>
                    <img src={`${S3_ASSETS_PATH}/images/checked-new.svg`} />
                    <span style={{ color: '#0091B3' }}>Yes</span>
                  </>
                ) : (
                  <>
                    <img
                      src={`${S3_ASSETS_PATH}/images/icon-checkbox-disabled.svg`}
                    />
                    <span style={{ fontWeight: 400 }}>Yes</span>
                  </>
                )}
              </div>
              <div className="view-item">
                {props.value === false ? (
                  <>
                    <img
                      src={`${S3_ASSETS_PATH}/images/negative-checked.svg`}
                    />
                    <span style={{ color: '#D41C54' }}>No</span>
                  </>
                ) : (
                  <>
                    <img
                      src={`${S3_ASSETS_PATH}/images/icon-checkbox-disabled.svg`}
                    />
                    <span style={{ fontWeight: 400 }}>No</span>
                  </>
                )}
              </div>
              {props.value && additionalSetup && (
                <div className={classes.iconContainer}>
                  <IconButton
                    classes={{ root: classes.viewIconButton }}
                    aria-label="view"
                    disableRipple
                    onClick={handleVisibilityClick}
                    data-testid="campaignAttributeNewSetAddtionalSetupButton"
                  >
                    {additionalSetupActive ? (
                      <FontAwesomeIcon
                        icon={faAngleUp}
                        color="#0091B3"
                        size="xs"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        color="#0091B3"
                        size="xs"
                      />
                    )}
                  </IconButton>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {props.value && additionalSetup && (
          <div
            style={{ display: additionalSetupActive ? 'block' : 'none' }}
            data-testid="campaignAttributeNewSetAddtionalSetup"
          >
            {additionalSetup}
          </div>
        )}
      </div>
    </Grid>
  ) : (
    <Grid item xs={props.xs ? props.xs : 6} style={props.style}>
      <div
        className={
          props.error && props.error[props.id]
            ? `campaign-attribute error`
            : `campaign-attribute`
        }
        style={{
          height: props.notes ? '100%' : 'unset',
          maxHeight: props.notes ? 'unset' : 'inherit',
        }}
      >
        <Grid
          style={{ height: props.notes ? '100%' : 'unset' }}
          container
          alignItems="center"
        >
          <Grid item className="title" style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
              data-testid="campaignAttributeNewLabel"
            >
              <p>
                {props.label}
                <ToolTip title={props.tooltip} placement="right-end" />
              </p>
              {props.notes &&
                props.notes.map((note, index) => (
                  <Box
                    key={index}
                    className={classes.note}
                    data-testid={`campaignAttributeNewNote-${index}`}
                  >
                    {note}
                  </Box>
                ))}
            </div>
          </Grid>
          <Grid item className="buttons" style={{ width: 193 }}>
            <div
              className="check-box-span yes"
              data-test="campaignAttributeNewSetAdditionalSetupActiveYes"
            >
              <FormControlLabel
                control={
                  <CheckBox
                    checked={props.campaignInfo[props.id] === true}
                    handleCheckbox={handleYesClick}
                  />
                }
                label={
                  <span
                    className={`paragraph ${
                      props.campaignInfo[props.id] === true ? 'checked' : ''
                    }`}
                  >
                    Yes
                  </span>
                }
                labelPlacement="end"
              />
            </div>
            <div
              className="check-box-span"
              data-test="campaignAttributeNewSetAdditionalSetupActiveNo"
            >
              <FormControlLabel
                control={
                  <CheckBox
                    checked={props.campaignInfo[props.id] === false}
                    handleCheckbox={handleNoClick}
                    negative={true}
                    disabled={props.singleVal}
                  />
                }
                label={
                  <span
                    className={`paragraph ${
                      props.campaignInfo[props.id] === false
                        ? 'checked false'
                        : ''
                    }`}
                    style={props.singleVal ? { opacity: 0.5 } : {}}
                  >
                    No
                  </span>
                }
                labelPlacement="end"
              />
            </div>
            {additionalSetup && additionalSetupTriggered && (
              <div
                className={classes.iconContainer}
                data-test="campaignAttributeHandleVisibilty"
              >
                <IconButton
                  classes={{ root: classes.iconButton }}
                  aria-label="view"
                  disableRipple
                  onClick={handleVisibilityClick}
                >
                  {additionalSetupActive ? (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      color="#0091B3"
                      size="xs"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      color="#0091B3"
                      size="xs"
                    />
                  )}
                </IconButton>
              </div>
            )}
          </Grid>
          {/* <Grid item xs={7} className="info"></Grid> */}
        </Grid>
        {additionalSetup && (
          <div
            style={{ display: additionalSetupActive ? 'block' : 'none' }}
            data-test="campaignAttributeAddtionalSetup"
          >
            {additionalSetup}
          </div>
        )}
        {props.error && props.error[props.id] ? (
          <h6 className="error-msg">{props.error[props.id]}</h6>
        ) : null}
      </div>
    </Grid>
  );
};

CampaignAttributeNew.propTypes = {
  additionalSetup: PropTypes.node,
  additionalSetupActive: PropTypes.bool,
  setAdditionalSetupActive: PropTypes.func,
};

export default CampaignAttributeNew;
