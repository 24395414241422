import { Button, Table, TableFilter } from 'portal-commons';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid, withStyles } from '@material-ui/core';

import {
  getCampaignsList,
  findAllUsecaseTypes,
  downloadCampaignsAsCsv,
} from '../apiServices';
import { CampaignListingRow } from '../components';
import DownloadLimitedModal from '../components/DownloadLimitedModal';
import '../../../../assets/styles/campaign-listing-module.scss';
import { S3_ASSETS_PATH } from '../../../../constants';
import { Header } from '../../../../shared_elements';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../shared_elements/actions';
import Loader from '../../../../shared_elements/containers/Loader';
import { globalGetService } from '../../../../utils/globalApiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CampaignFilterErrorCode as filterErrorCode } from '../../index';
import { fieldValidation } from '../../../../utils/validator';

const filterConfigs = {
  campaignUid: {
    type: 'text',
    label: 'Campaign ID',
    placeholder: 'Enter campaign id',
    width: 150,
  },
  usecase: {
    type: 'dropdown',
    label: 'Use-Case',
    placeholder: 'Select use-case',
    options: [],
    width: 230,
  },
  brandUid: {
    type: 'text',
    label: 'Brand ID',
    placeholder: 'Enter brand id',
    width: 150,
  },
  brandName: {
    type: 'text',
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
    width: 260,
  },
  resellerName: {
    type: 'text',
    label: 'Reseller Name',
    placeholder: 'Enter reseller name',
    suggestions: [],
    width: 260,
  },
  referenceId: {
    type: 'text',
    label: 'Reference ID',
    placeholder: 'Enter reference id',
    width: 150,
    error: false,
    helperText: '',
  },
  status: {
    type: 'dropdown',
    label: 'TCR Status',
    placeholder: 'Select TCR status',
    options: [
      {
        label: 'Active',
        value: 'ACTIVE',
      },
      {
        label: 'Deactivated',
        value: 'EXPIRED',
      },
    ],
    width: 230,
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'resellerName', label: 'RESELLER NAME', sortable: false },
  { id: 'status', label: 'TCR STATUS', sortable: true },
];

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 600,
    fontSize: '27px',
    lineHeight: '32px',
    color: '#19262A',
  },
  note: {
    marginTop: 5,
    marginLeft: '12px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666E71',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
  spacing: {
    flex: 1,
  },
};

class CampaignsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      campaignInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      noCampaignsFound: false,
      downloadLimited: false,
      filtersShown: false,
      // configs and values used by table filter component
      filterConfigs,
      candidateFilters: {},
      appliedFilters: {},
      // additional filters implicitly set by default
      additionalFilters: {},
      isRoleUserOrManager: false,
    };

    if (localStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(localStorage.userInfo);
      const hasRoleUserOrManager = userInfo.groups.some((role) => {
        return role === 'ROLE_USER' || role === 'ROLE_MANAGER';
      });
      this.state.isRoleUserOrManager = hasRoleUserOrManager;
    }

    this.getCampaignsList = getCampaignsList.bind(this);
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Campaigns';
    findAllUsecaseTypes().then((response) => {
      this.generateFilterOptions('usecase', response.data);
    });
    this.updateFiltersAndFetchCampaigns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateFiltersAndFetchCampaigns(prevProps.location.search);
    }
  }

  generateFilterOptions = (key, data) => {
    const options = data.map((item) => {
      return { label: item.displayName, value: item.uid ? item.uid : item.id };
    });
    this.setState((prevState) => ({
      ...prevState,
      filterConfigs: {
        ...prevState.filterConfigs,
        [key]: {
          ...prevState.filterConfigs[key],
          options,
        },
      },
    }));
  };

  updateFiltersAndFetchCampaigns = (previousSearch = '') => {
    const { search } = this.props.location;
    const { candidateFilters, appliedFilters, additionalFilters } = this.state;
    const queries = queryString.parse(search, {
      decode: true,
    });
    const previousQueries = queryString.parse(previousSearch, {
      decode: true,
    });
    const queryEntries = Object.entries(queries);
    if (queryEntries.length > 0) {
      const newCandidateFilters = { ...candidateFilters };
      Object.keys(newCandidateFilters).forEach((key) => {
        if (queries[key] === undefined && previousQueries[key] !== undefined) {
          delete newCandidateFilters[key];
        }
      });
      const newAppliedFilters = { ...appliedFilters };
      Object.keys(filterConfigs).forEach((key) => {
        if (queries[key]) {
          if (!previousSearch) {
            newCandidateFilters[key] = queries[key];
          }
          newAppliedFilters[key] = queries[key];
        } else {
          delete newAppliedFilters[key];
        }
      });
      const newAdditionalFilters = {
        sortField: 'createDate',
        ascendingOrder: false,
        ...additionalFilters,
      };
      queryEntries.forEach(([key, value]) => {
        if (!filterConfigs[key] && key !== 'page') {
          newAdditionalFilters[key] =
            key === 'ascendingOrder' ? JSON.parse(value) : value;
        }
      });
      this.setState({
        candidateFilters: newCandidateFilters,
        appliedFilters: newAppliedFilters,
        additionalFilters: newAdditionalFilters,
      });
      this.getCampaignsList({
        ...newAppliedFilters,
        ...newAdditionalFilters,
        page: queries.page ? queries.page : 1,
      });
    } else {
      const newAdditionalFilters = {
        sortField: 'createDate',
        ascendingOrder: false,
      };
      this.setState({
        candidateFilters: {},
        appliedFilters: {},
        additionalFilters: newAdditionalFilters,
      });
      this.getCampaignsList({
        ...newAdditionalFilters,
      });
    }
  };

  fetchBrandSuggestions = (query = {}) => {
    globalGetService('csp/brands/suggestions', { ...query, limit: 20 }).then(
      (response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState((prevState) => ({
            filterConfigs: {
              ...prevState.filterConfigs,
              brandName: {
                ...prevState.filterConfigs.brandName,
                suggestions: response.data.map(
                  (suggestion) => suggestion.brandName
                ),
              },
            },
          }));
        }
      }
    );
  };

  fetchResellerSuggestions = (query = {}) => {
    globalGetService('csp/reseller/suggestion', { ...query, limit: 20 }).then(
      (response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState((prevState) => ({
            filterConfigs: {
              ...prevState.filterConfigs,
              resellerName: {
                ...prevState.filterConfigs.resellerName,
                suggestions: response.data.map(
                  (suggestion) => suggestion.companyName
                ),
              },
            },
          }));
        }
      }
    );
  };

  handleChangePage = (newPage) => {
    const { appliedFilters, additionalFilters } = this.state;
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...appliedFilters, ...additionalFilters, page: newPage },
        { encode: false }
      )}`,
    });
  };

  createSortHandler = (sortField) => {
    const { appliedFilters, campaignInfo, additionalFilters } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (campaignInfo.totalRecords) {
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            ...appliedFilters,
            ...additionalFilters,
            page: campaignInfo.page,
            sortField,
            ascendingOrder: !additionalFilters.ascendingOrder,
          },
          { encode: false }
        )}`,
      });
    }
  };

  handleTableFilterToggle = () => {
    const { filtersShown } = this.state;
    this.setState({
      filtersShown: !filtersShown,
    });
  };

  resetFilterConfigsError = (newValues) => {
    const { filterConfigs: tempFilterConfigs, candidateFilters } = this.state;
    Object.keys(newValues).forEach((key) => {
      if (
        tempFilterConfigs[key]?.error &&
        newValues[key] !== candidateFilters[key]
      ) {
        tempFilterConfigs[key].error = false;
        tempFilterConfigs[key].helperText = '';
      }
    });
    this.setState({ filterConfigs: tempFilterConfigs });
  };

  validateFilters = (values) => {
    const { filterConfigs: tempFilterConfigs } = this.state;
    Object.keys(values).forEach((key) => {
      if (filterErrorCode[key]) {
        const code = fieldValidation({
          ...filterErrorCode[`${key}Obj`],
          fieldval: values[key],
        });
        if (code !== 0) {
          tempFilterConfigs[key].error = true;
          tempFilterConfigs[key].helperText = filterErrorCode[key][code];
        }
      }
    });
    this.setState({
      filterConfigs: tempFilterConfigs,
    });
    return Object.keys(values).every((key) => !tempFilterConfigs[key].error);
  };

  handleCandidateFiltersChange = (values) => {
    const { filterConfigs, candidateFilters } = this.state;
    this.resetFilterConfigsError(values);
    this.setState({
      candidateFilters: values,
    });

    if (values.brandName !== candidateFilters.brandName) {
      if (values.brandName && values.brandName.length > 1) {
        this.fetchBrandSuggestions({ prefix: values.brandName });
      } else {
        this.setState({
          filterConfigs: {
            ...filterConfigs,
            brandName: {
              ...filterConfigs.brandName,
              suggestions: [],
            },
          },
        });
      }
    }

    if (values.resellerName !== candidateFilters.resellerName) {
      if (values.resellerName && values.resellerName.length > 1) {
        this.fetchResellerSuggestions({
          companyNamePrefix: values.resellerName,
        });
      } else {
        this.setState({
          filterConfigs: {
            ...filterConfigs,
            resellerName: {
              ...filterConfigs.resellerName,
              suggestions: [],
            },
          },
        });
      }
    }
  };

  handleAppliedFiltersChange = (values) => {
    if (!this.validateFilters(values)) {
      return;
    }

    if (Object.keys(values).length === 0) {
      this.resetFilterConfigsError(this.state.filterConfigs);
    }

    this.setState({
      appliedFilters: values,
    });

    this.props.history.push({
      search: `?${queryString.stringify(
        { ...values, ...this.state.additionalFilters },
        { encode: false }
      )}`,
    });
  };

  handleDownload = async () => {
    try {
      this.props.setLoadingMessage('We are generating the document');
      const response = await downloadCampaignsAsCsv({
        ...this.state.appliedFilters,
        ...this.state.additionalFilters,
      });
      const file = new File([response], 'my-campaigns.csv');
      const url = URL.createObjectURL(file);
      const element = document.createElement('a');
      element.href = url;
      element.download = file.name;
      document.body.appendChild(element);
      element.click();
      this.props.clearLoadingMessage();
    } catch (e) {}
  };

  toggleDownloadLimitedModal = (on) => {
    this.setState({
      downloadLimited: on,
    });
  };

  render() {
    const { classes, history } = this.props;
    const {
      campaignInfo,
      tableLoader,
      noCampaignsFound,
      loader,
      filtersShown,
      filterConfigs,
      candidateFilters,
      appliedFilters,
      additionalFilters,
      isRoleUserOrManager,
    } = this.state;
    return (
      <>
        <section
          className="campaigns-listing-section"
          style={{ padding: '0px' }}
          data-testid="campaignListing"
        >
          {this.state.loader ? (
            <Loader />
          ) : (
            <>
              {!this.state.noCampaignsFound ? (
                <Container maxWidth={false} style={{ padding: '0px' }}>
                  <div className={classes.title}>
                    <span>
                      {!loader && !noCampaignsFound
                        ? `${campaignInfo.displayTotal} Campaigns`
                        : 'Campaigns'}
                    </span>
                    <span className={classes.note}>
                      Results capped at first 10,000 records
                    </span>
                  </div>
                  <div className={classes.buttons}>
                    <Button
                      color="secondary"
                      onClick={this.handleTableFilterToggle}
                      data-testid="tableAddFilterButton"
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
                    </Button>
                    <Button
                      variant="outline"
                      color="secondary"
                      onClick={() => {
                        if (campaignInfo.displayTotal > 10000) {
                          this.toggleDownloadLimitedModal(true);
                        } else {
                          this.handleDownload();
                        }
                      }}
                      data-testid="tableDownloadButton"
                    >
                      <FontAwesomeIcon icon={faArrowDownToLine} />
                      Download
                    </Button>
                    {isRoleUserOrManager && (
                      <>
                        <div className={classes.spacing} />
                        <Button
                          variant="outline"
                          onClick={() => history.push('/campaign/create')}
                          data-testid="campaignListingAddButton"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          Add Campaign
                        </Button>
                      </>
                    )}
                  </div>
                  {filtersShown && (
                    <Grid container style={{ marginBottom: 12 }}>
                      <TableFilter
                        configs={filterConfigs}
                        candidateValues={candidateFilters}
                        appliedValues={appliedFilters}
                        onCandidateValuesChange={
                          this.handleCandidateFiltersChange
                        }
                        onAppliedValuesChange={this.handleAppliedFiltersChange}
                      />
                    </Grid>
                  )}
                  <Grid container justify="center" spacing={0}>
                    <Table
                      testId="campaignsListingListingTableNew"
                      loading={tableLoader}
                      emptyState="no campaigns to view"
                      records={{ total: campaignInfo.totalRecords }}
                      handleChangePage={this.handleChangePage}
                      createSortHandler={this.createSortHandler}
                      filter={additionalFilters}
                      headRows={headRows}
                      tableData={campaignInfo.records.map((record) => (
                        <CampaignListingRow data={record} key={record.uid} />
                      ))}
                      pagination={{
                        count: Math.ceil(
                          campaignInfo.totalRecords /
                            campaignInfo.recordsPerPage
                        ),
                        page: campaignInfo.page,
                        rowsPerPage: campaignInfo.recordsPerPage,
                        totalRecords: campaignInfo.totalRecords,
                      }}
                    />
                  </Grid>
                </Container>
              ) : (
                <Container maxWidth={false} style={{ padding: '0px' }}>
                  <Grid
                    container
                    className="no-campaigns-found"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item className="text-center">
                      <div className="outer-wrapper">
                        <img
                          src={`${S3_ASSETS_PATH}/images/no-campaigns-found.svg`}
                          alt=""
                        />
                        <h3 className="heading1">Please register a Campaign</h3>
                        <p className="heading1">No Campaigns found</p>
                      </div>
                      {this.props.access.includes('CREATE_CAMPAIGNS') ? (
                        <div className="bottom-blk">
                          <Link
                            to="/campaign/create"
                            className="primary-btn"
                            data-testid="campaignsListingRegisterACampaignButton"
                          >
                            Register a Campaign
                          </Link>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                </Container>
              )}{' '}
            </>
          )}
        </section>
        <DownloadLimitedModal
          open={this.state.downloadLimited}
          onClose={() => this.toggleDownloadLimitedModal(false)}
          onSubmit={() => {
            this.toggleDownloadLimitedModal(false);
            this.handleDownload();
          }}
        />
      </>
    );
  }
}

CampaignsListing.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setLoadingMessage: PropTypes.func,
  clearLoadingMessage: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoadingMessage: (message) =>
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    }),
  clearLoadingMessage: () =>
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CampaignsListing));
