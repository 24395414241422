import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';

import Section from './Section';
import { currentAndMaxValuesToText, numberValueToText } from '../utils';

interface AccountUsageSectionProps {
  currentPortalUsers?: number;
  maxPortalUsers?: number;
  maxCampaignsPerBrand?: number;
  currentBrands?: number;
  maxBrands?: number;
}

const Description = styled.div`
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #49535d;
`;

const Link = styled.a`
  color: #0091b3;
  text-decoration: underline;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 237px 1fr 237px 1fr;
  row-gap: 12px;
  padding-top: 30px;
  border-top: 1px solid #cccfd0;
  font-family: Roboto, sans-serif;
`;

const Field = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const Value = styled.span`
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const AccountUsageSection: FunctionComponent<AccountUsageSectionProps> = ({
  currentPortalUsers,
  maxPortalUsers,
  maxCampaignsPerBrand,
  currentBrands,
  maxBrands,
}) => {
  return (
    <Section
      icon={faCircleUser}
      title="Account Usage"
      testId="accountUsageSection"
    >
      <Description>
        Brand/Campaign Maximums are adjustable upon request without cost. Portal
        users can be added in increments of 10 with an associated cost. Please
        reach out to{' '}
        <Link href="mailto:support@campaignregistry.com">
          support@campaignregistry.com
        </Link>{' '}
        for information.
      </Description>
      <Content>
        <Field>Max Portal Users:</Field>
        <Value data-testid="maxPortalUsers">
          {currentAndMaxValuesToText(currentPortalUsers, maxPortalUsers)}
        </Value>

        <Field>Max Campaigns Per Brand:</Field>
        <Value data-testid="maxCampaignsPerBrand">
          {numberValueToText(maxCampaignsPerBrand)}
        </Value>

        <Field>Max Brands Per CSP Account:</Field>
        <Value data-testid="maxBrandsPerAccount">
          {currentAndMaxValuesToText(currentBrands, maxBrands)}
        </Value>
      </Content>
    </Section>
  );
};

export default AccountUsageSection;
