import {
  Box,
  BoxV2,
  MultimediaList,
  MultimediaUploader,
  AttachmentPreview,
} from 'portal-commons';
import React, { useState } from 'react';
import {
  faTag,
  faBullhorn,
  faMessageDots,
  faFileImage,
  faSquarePollHorizontal,
  faArrowProgress,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import CampaignAttributeNew from './CampaignAttributeNew';
import UploadSizeExceededModal from './UploadSizeExceededModal';
import {
  MAX_FILE_SIZE,
  MAX_SUPPORTING_DOCUMENT_COUNT,
  MAX_SAMPLE_MULTIMEDIA_COUNT,
} from '../constants';
import { charCounter } from '../utils';
import IntegrationReactSelect from '../../../../ui_elements/IntegrationReactSelect';
import ToolTip from '../../../../ui_elements/ToolTip';
import MessageFlowAttributePopup from './MessageFlowAttributePopup';
import EmbeddedLinkPopup from './EmbeddedLinkPopup';

const useStyles = makeStyles({
  button: {
    background: '#D41C54',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      background: '#D41C54',
    },
  },
  setup: {
    '& > *:not(:first-child)': {
      marginTop: '15px',
    },
  },
  'MuiFormHelperText-root': {
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
    },
  },
  popup: {
    margin: '5px 0',
    fontSize: '12px',
    background: '#68737A',
  },
});

export default function CampaignDetailsForm(props) {
  const {
    brandName,
    usecase,
    campaignInfo,
    error,
    sampleMessage,
    sampleMultimedia,
    termsAndConditions,
    cnpOptions = [],
    resellers = [],
    mnoInfo,
    supportedFileFormatsForSampleMultimedia,
    supportedFileFormatsForSupportingDocument,
    supportingDocument,
  } = props;
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [sampleMultimediaIndex, setSampleMultimediaIndex] = useState(-1);
  const [sampleMultimediaLoading, setSampleMultimediaLoading] = useState(false);
  const [supportingDocumentFile, setSupportingDocumentFile] = useState(null);
  const [supportingDocumentLoading, setSupportingDocumentLoading] =
    useState(false);
  const [activeSupportingDocumentIndex, setActiveSupportingDocumentIndex] =
    useState(-1);
  const [uploadSizeExceededOpen, setUploadSizeExceededOpen] = useState(false);
  const resellersOptions = resellers.map((item, index) => {
    return { label: item.companyName, value: item.uid };
  });
  const udpatedResellersOptions = [
    { label: 'No Reseller', value: null },
    { label: 'Add New Reseller', value: 'new' },
    ...resellersOptions,
  ];
  const messageCount = Object.keys(sampleMessage).length;
  const subscriberOptinRequired =
    mnoInfo &&
    mnoInfo.mnoMetadata &&
    Object.entries(mnoInfo.mnoMetadata).some(
      ([key, value]) => !!value.reqSubscriberOptin
    );
  const subscriberOptoutRequired =
    mnoInfo &&
    mnoInfo.mnoMetadata &&
    Object.entries(mnoInfo.mnoMetadata).some(
      ([key, value]) => !!value.reqSubscriberOptout
    );
  const subscriberHelpRequired =
    mnoInfo &&
    mnoInfo.mnoMetadata &&
    Object.entries(mnoInfo.mnoMetadata).some(
      ([key, value]) => !!value.reqSubscriberHelp
    );

  const handleUploadChange = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      setFile(null);
      setUploadSizeExceededOpen(true);
    } else {
      setFile(file);
    }
  };

  const handleSupportingDocumentFileChange = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      setSupportingDocumentFile(null);
      setUploadSizeExceededOpen(true);
    } else {
      setSupportingDocumentFile(file);
    }
  };

  return (
    <>
      <Grid item xs={12} data-testid="campaignDetailsFormBrand">
        <div className="brand wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faTag} size="xl" color="#49535D" />
              </Box>
              <h3 className="heading1">Brand</h3>
            </Box>
          </div>
          <Grid container>
            <Grid item xs={6}>
              <div className="form-group-field">
                <Tooltip classes={{ tooltip: classes.popup }} title={brandName}>
                  <TextField
                    data-testid="campaignDetailsFormBrandInput"
                    label="Brand Name"
                    value={brandName}
                    id="brandId"
                    fullWidth={true}
                    inputProps={{
                      'aria-label': 'brandId',
                      style: { overflow: 'hidden', textOverflow: 'ellipsis' },
                    }}
                    disabled={true}
                  />
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} data-testid="campaignDetailsFormCampaignDetails">
        <div className="campaign-details wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faBullhorn} size="xl" color="#49535D" />
              </Box>
              <h3 className="heading1">Campaign Details</h3>
            </Box>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  data-testid="campaignDetailsFormCampaignDetailsUseCaseInput"
                  label="Use-Case"
                  value={usecase.id}
                  id="brandId"
                  fullWidth={true}
                  inputProps={{ 'aria-label': 'brandId' }}
                  disabled={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  error={error.description ? true : false}
                  value={campaignInfo.description}
                  multiline={true}
                  required={true}
                  rows={2}
                  rowsMax={3}
                  classes={{
                    root: classes['MuiFormHelperText-root'],
                  }}
                  id="description"
                  data-testid="campaignDetailsFormCampaignDetailsDescriptionInput"
                  label="Campaign Description"
                  fullWidth={true}
                  inputProps={{
                    'aria-label': 'description',
                    maxLength: 4096,
                    minLength: 40,
                  }}
                  onChange={(event) =>
                    props.handleChange(event.target.value, 'description')
                  }
                  onBlur={(event) =>
                    props.handleError(event.target.value, 'description')
                  }
                  helperText={charCounter({
                    value: campaignInfo.description,
                    maxLength: 4096,
                  })}
                />
                {error.description ? (
                  <h6 className="error-msg">{error.description}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  error={!!error.messageFlow}
                  classes={{
                    root: classes['MuiFormHelperText-root'],
                  }}
                  fullWidth
                  multiline
                  required
                  rows={2}
                  rowsMax={3}
                  id="messageFlow"
                  data-testid="campaignDetailsFormCampaignDetailMessageFlowInput"
                  label="Call-to-Action / Message Flow"
                  inputProps={{
                    'aria-label': 'message flow',
                    maxLength: 4096,
                    minLength: 40,
                  }}
                  value={props.messageFlow}
                  onChange={(event) =>
                    props.handleMessageFlowChange(event.target.value)
                  }
                  onBlur={(event) =>
                    props.handleError(event.target.value, 'messageFlow')
                  }
                  helperText={charCounter({
                    value: props.messageFlow,
                    maxLength: 4096,
                  })}
                />
                <ToolTip
                  title="This field should describe how a consumer opts-in to the campaign, therefore giving consent to the sender to receive their messages. The call-to-action must be explicitly clear and inform the consumer of the nature of the program. If multiple opt-in methods can be used for the same campaign, you must list them all."
                  style={{ top: '25px' }}
                  placement="right-end"
                />
                {error.messageFlow ? (
                  <h6 className="error-msg">{error.messageFlow}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  classes={{
                    root: classes['MuiFormHelperText-root'],
                  }}
                  inputProps={{
                    'aria-label': 'terms and conditions link',
                    maxLength: 255,
                  }}
                  fullWidth
                  error={!!error.termsAndConditionsLink}
                  id="termsAndConditionsLink"
                  data-testid="campaignDetailsFormCampaignDetailTermsAndConditionsLinkInput"
                  label="Terms and Conditions Link"
                  helperText={charCounter({
                    value: campaignInfo.termsAndConditionsLink,
                    maxLength: 255,
                  })}
                  value={campaignInfo.termsAndConditionsLink}
                  onChange={(event) =>
                    props.handleChange(
                      event.target.value,
                      'termsAndConditionsLink'
                    )
                  }
                  onBlur={(event) =>
                    props.handleError(
                      event.target.value,
                      'termsAndConditionsLink'
                    )
                  }
                />
                {error.termsAndConditionsLink ? (
                  <h6 className="error-msg">{error.termsAndConditionsLink}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  classes={{
                    root: classes['MuiFormHelperText-root'],
                  }}
                  inputProps={{
                    'aria-label': 'privacyPolicyLink',
                    maxLength: 255,
                  }}
                  fullWidth
                  error={!!error.privacyPolicyLink}
                  id="privacyPolicyLink"
                  data-testid="campaignDetailsFormCampaignDetailsPrivacyPolicyLinkInput"
                  label="Privacy Policy Link"
                  helperText={charCounter({
                    value: campaignInfo.privacyPolicyLink,
                    maxLength: 255,
                  })}
                  value={campaignInfo.privacyPolicyLink}
                  onChange={(event) =>
                    props.handleChange(event.target.value, 'privacyPolicyLink')
                  }
                  onBlur={(event) =>
                    props.handleError(event.target.value, 'privacyPolicyLink')
                  }
                />
                {error.privacyPolicyLink ? (
                  <h6 className="error-msg">{error.privacyPolicyLink}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} data-testid="campaignDetailsFormCtaMultimedia">
        <div className="sample-multimedia wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faBullhorn} size="xl" color="#49535D" />
              </Box>
              <h3 className="heading1">
                CTA (Call-to-Action), Privacy Policy and/or Terms and Conditions
                Multimedia Upload
              </h3>
            </Box>
            <BoxV2
              sx={{
                marginTop: '8px',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '16px',
              }}
            >
              Provides an area to upload any supporting information for opt in,
              call-to-action, terms and conditions, privacy policy, etc. Not
              intended for MMS sample messages.
            </BoxV2>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <MultimediaUploader
                disabled={
                  supportingDocument.length >= MAX_SUPPORTING_DOCUMENT_COUNT
                }
                disabledText={`Max ${MAX_SUPPORTING_DOCUMENT_COUNT} uploads per campaign is allowed`}
                supportedMimeTypes={supportedFileFormatsForSupportingDocument}
                onChange={handleSupportingDocumentFileChange}
              />
            </Grid>
            <Grid item xs={6}>
              <MultimediaList
                editable
                title="Multimedia Files"
                loading={
                  activeSupportingDocumentIndex > -1 &&
                  !supportingDocument[activeSupportingDocumentIndex].file
                }
                attachments={supportingDocument}
                onDelete={props.handleSupportingDocumentDelete}
                onSelect={setActiveSupportingDocumentIndex}
                onDownload={props.handleSupportingDocumentDownload}
              />
            </Grid>
            {supportingDocument.length < MAX_SUPPORTING_DOCUMENT_COUNT && (
              <Grid item xs={12}>
                <div className="text-center">
                  <Button
                    classes={{
                      root: classes.button,
                    }}
                    disabled={
                      !supportingDocumentFile || supportingDocumentLoading
                    }
                    variant="contained"
                    color="primary"
                    data-testid="campaignDetailsFormSupportingDocumentAddButton"
                    onClick={async () => {
                      if (supportingDocumentFile) {
                        setSupportingDocumentLoading(true);
                        await props.handleSupportingDocumentAdd(
                          supportingDocumentFile
                        );
                        setSupportingDocumentLoading(false);
                        setSupportingDocumentFile(null);
                      }
                    }}
                  >
                    {supportingDocumentLoading
                      ? 'Processing...'
                      : '+ Add Sample Multimedia'}
                  </Button>
                </div>
              </Grid>
            )}
            {activeSupportingDocumentIndex > -1 &&
              supportingDocument[activeSupportingDocumentIndex].file && (
                <AttachmentPreview
                  onClose={() => setActiveSupportingDocumentIndex(-1)}
                  file={supportingDocument[activeSupportingDocumentIndex].file}
                  mimeType={
                    supportingDocument[activeSupportingDocumentIndex].mimeType
                  }
                  data-testid="campaignDetailsFormSupportingDocumentBackdrop"
                  style={{ zIndex: 5 }}
                />
              )}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} data-testid="campaignDetailsFormSampleMessages">
        <div className="sample-messages wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon
                  icon={faMessageDots}
                  size="xl"
                  color="#49535D"
                />
              </Box>
              <h3 className="heading1">Sample Messages</h3>
            </Box>
          </div>
          <Grid container spacing={4}>
            {messageCount > 0 &&
              Object.keys(sampleMessage).map((key, index) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  className={
                    messageCount % 2 == 0 ||
                    (messageCount % 2 != 0 && index < messageCount - 1)
                      ? 'message-grid'
                      : ''
                  }
                >
                  <div className="form-group-field sample-message">
                    <label>
                      <Box flexDirection="row" alignItems="self-end">
                        {`Sample message ${index + 1}`}
                        {index > 0 ? (
                          <Box margin={{ left: 'xs' }}>
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ cursor: 'pointer' }}
                              color="#0091B3"
                              size="lg"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.handleSampleMessageDelete(
                                  `sample${index + 1}`
                                );
                              }}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </label>
                    <TextField
                      autoFocus={index > 0 ? true : false}
                      error={error[key] ? true : false}
                      value={sampleMessage[key]}
                      multiline={true}
                      rows={3}
                      rowsMax={3}
                      id="description"
                      data-testid="campaignDetailsFormSampleMessagesDescriptionInput"
                      // label={`Message example ${index}`}
                      fullWidth={true}
                      inputProps={{
                        'aria-label': 'description',
                        maxLength: 1024,
                      }}
                      onChange={(event) =>
                        props.handleMessageTextChange(event.target.value, key)
                      }
                      onBlur={(e) => props.handleError(e.target.value, key)}
                      classes={{
                        root: classes['MuiFormHelperText-root'],
                      }}
                      helperText={charCounter({
                        value: sampleMessage[key],
                        maxLength: 1024,
                      })}
                    />
                    {error[key] ? (
                      <h6 className="error-msg">{error[key]}</h6>
                    ) : null}
                  </div>
                </Grid>
              ))}
            {messageCount < 5 && (
              <Grid
                item
                xs={messageCount % 2 == 1 ? 6 : 12}
                style={{ alignSelf: 'center' }}
              >
                <div className="add-message-btn text-center">
                  <p>
                    <a
                      data-testid="campaignDetailsFormSampleMessagesAddButton"
                      className="primary-btn"
                      onClick={props.handleSampleMessageClick}
                    >
                      + Add Sample Message
                    </a>
                  </p>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} data-testid="campaignDetailsFormSampleMultimedia">
        <div className="sample-multimedia wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faFileImage} size="xl" color="#49535D" />
              </Box>
              <h3 className="heading1">Sample Multimedia</h3>
            </Box>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <MultimediaUploader
                disabled={
                  sampleMultimedia.length >= MAX_SAMPLE_MULTIMEDIA_COUNT
                }
                disabledText={`Max ${MAX_SAMPLE_MULTIMEDIA_COUNT} uploads per campaign is allowed`}
                supportedMimeTypes={supportedFileFormatsForSampleMultimedia}
                onChange={handleUploadChange}
              />
            </Grid>
            <Grid item xs={6}>
              <MultimediaList
                data-testid="campaignDetailSampleMultimediaFiles"
                title="Sample Multimedia Files"
                editable
                loading={
                  sampleMultimediaIndex > -1 &&
                  !sampleMultimedia[sampleMultimediaIndex].file
                }
                attachments={sampleMultimedia}
                onSelect={setSampleMultimediaIndex}
                onDelete={(index) => {
                  props.handleSampleMultimediaDelete(index);
                }}
                onDownload={(index) => {
                  props.handleSampleMultimediaDownload(index);
                }}
              />
            </Grid>
            {sampleMultimedia.length < 5 && (
              <Grid item xs={12}>
                <div className="text-center">
                  <Button
                    classes={{
                      root: classes.button,
                    }}
                    disabled={!file || sampleMultimediaLoading}
                    variant="contained"
                    color="primary"
                    data-testid="campaignDetailsFormSampleMultimediaAddButton"
                    onClick={async () => {
                      if (file) {
                        setSampleMultimediaLoading(true);
                        await props.handleSampleMultimediaAdd(file);
                        setSampleMultimediaLoading(false);
                        setFile(null);
                      }
                    }}
                  >
                    {sampleMultimediaLoading
                      ? 'Processing...'
                      : '+ Add Sample Multimedia'}
                  </Button>
                </div>
              </Grid>
            )}
            {sampleMultimediaIndex > -1 &&
              !!sampleMultimedia[sampleMultimediaIndex].file && (
                <AttachmentPreview
                  onClose={() => setSampleMultimediaIndex(-1)}
                  file={sampleMultimedia[sampleMultimediaIndex].file}
                  mimeType={sampleMultimedia[sampleMultimediaIndex].mimeType}
                  data-testid="campaignDetailsFormSampleMultimediaBackdrop"
                  style={{ zIndex: 5 }}
                />
              )}
          </Grid>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        data-testid="campaignDetailsFormCampaignAndContentAttributes"
      >
        <div className="campaign-attributes wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon
                  icon={faSquarePollHorizontal}
                  size="xl"
                  color="#49535D"
                />
              </Box>
              <h3 className="heading1">Campaign and Content Attributes</h3>
            </Box>
            <span>
              (For M2M (Machine-to-Machine) Use Case, please select No if the
              attributes are not applicable.)
            </span>
          </div>
          <Grid container spacing={3}>
            <CampaignAttributeNew
              handleChange={props.handleChange}
              id="subscriberOptin"
              label="Subscriber Opt-in"
              campaignInfo={campaignInfo}
              tooltip="You are collecting and processing consumer opt-ins."
              error={error}
              singleVal={subscriberOptinRequired}
              additionalSetup={
                <MessageFlowAttributePopup
                  // messageRequired={props.usecase.id !== 'M2M'}
                  label="Opt-in"
                  keywordsTooltip="A keyword consumers can text to opt-in."
                  messageTooltip="If consumers can text in a keyword, the response should include the Brand name, confirmation of opt-in enrollment to a recurring message campaign, how to get help, and clear description of how to opt-out."
                  error={error.optinMessage}
                  keywords={props.optinKeywords}
                  message={props.optinMessage}
                  onKeywordsChange={props.handleOptinKeywordsChange}
                  onMessageChange={props.handleOptinMessageChange}
                  onClose={() => props.handleOptinPopupChange(false)}
                  onMessageBlur={props.handleOptinMessageBlur}
                  showCharCounter={true}
                />
              }
              additionalSetupActive={props.optinPopup}
              setAdditionalSetupActive={props.handleOptinPopupChange}
            />
            <CampaignAttributeNew
              handleChange={props.handleChange}
              id="embeddedLink"
              label="Embedded Link"
              campaignInfo={campaignInfo}
              tooltip="Are you using an embedded link of any kind? Note that public URL shorteners (bitly, tinyurl) are not accepted."
              error={error}
              additionalSetup={
                <EmbeddedLinkPopup
                  error={error.embeddedLinkSample}
                  value={campaignInfo.embeddedLinkSample}
                  onChange={(value) =>
                    props.handleChange(value, 'embeddedLinkSample')
                  }
                  onBlur={props.handleEmbeddedLinkSampleBlur}
                  onClose={() => props.handleEmbeddedLinkPopupChange(false)}
                />
              }
              additionalSetupActive={props.embeddedLinkPopup}
              setAdditionalSetupActive={props.handleEmbeddedLinkPopupChange}
            />
          </Grid>
          <Grid container spacing={3}>
            <CampaignAttributeNew
              handleChange={props.handleChange}
              id="subscriberOptout"
              label="Subscriber Opt-Out"
              campaignInfo={campaignInfo}
              tooltip="You are collecting and processing consumer opt-outs."
              error={error}
              singleVal={subscriberOptoutRequired}
              additionalSetup={
                <MessageFlowAttributePopup
                  messageRequired={
                    props.usecase.id !== '2FA' && props.usecase.id !== 'M2M'
                  }
                  label="Opt-out"
                  keywordsTooltip="A keyword consumers can text to opt-out."
                  messageTooltip="The response to the STOP keyword may include the Brand name but should include an acknowledgement of opt-out request and confirmation that no further messages will be sent."
                  error={error.optoutMessage}
                  keywords={props.optoutKeywords}
                  message={props.optoutMessage}
                  onKeywordsChange={props.handleOptoutKeywordsChange}
                  onMessageChange={props.handleOptoutMessageChange}
                  onClose={() => props.handleOptoutPopupChange(false)}
                  onMessageBlur={props.handleOptoutMessageBlur}
                  showCharCounter={true}
                />
              }
              additionalSetupActive={props.optoutPopup}
              setAdditionalSetupActive={props.handleOptoutPopupChange}
            />
            <CampaignAttributeNew
              handleChange={props.handleChange}
              id="embeddedPhone"
              label="Embedded Phone Number"
              campaignInfo={campaignInfo}
              tooltip="Are you using an embedded phone number (except the required HELP information contact phone number)?"
              error={error}
            />
          </Grid>
          <Grid container spacing={3}>
            <CampaignAttributeNew
              handleChange={props.handleChange}
              id="subscriberHelp"
              label="Subscriber Help"
              campaignInfo={campaignInfo}
              tooltip="You have implemented message reply providing customers on how they can contact the message sender after they reply with the “HELP” keyword."
              error={error}
              singleVal={subscriberHelpRequired}
              additionalSetup={
                <MessageFlowAttributePopup
                  messageRequired={props.usecase.id !== 'M2M'}
                  label="Help"
                  keywordsTooltip="A keyword consumers use to get help or more information."
                  messageTooltip="The response to HELP keyword may include the Brand name and additional support contact information."
                  error={error.helpMessage}
                  keywords={props.helpKeywords}
                  message={props.helpMessage}
                  onKeywordsChange={props.handleHelpKeywordsChange}
                  onMessageChange={props.handleHelpMessageChange}
                  onClose={() => props.handleHelpPopupChange(false)}
                  onMessageBlur={props.handleHelpMessageBlur}
                  showCharCounter={true}
                />
              }
              additionalSetupActive={props.helpPopup}
              setAdditionalSetupActive={props.handleHelpPopupChange}
            />
            <CampaignAttributeNew
              handleChange={props.handleChange}
              id="ageGated"
              label="Age-Gated Content"
              campaignInfo={campaignInfo}
              tooltip="Will this campaign include any age-gated content as defined by Carrier and CTIA guidelines?"
              error={error}
            />
          </Grid>
          <Grid container spacing={3}>
            <Grid item container xs={6}>
              <CampaignAttributeNew
                xs={12}
                handleChange={props.handleChange}
                id="numberPool"
                label="Number Pooling"
                campaignInfo={campaignInfo}
                tooltip="Select this if you intend on using 50+ numbers as this will require a different provisioning process on T-Mobile"
                error={error}
              />
              <CampaignAttributeNew
                style={{ marginTop: 12 }}
                xs={12}
                handleChange={props.handleChange}
                id="directLending"
                label="Direct Lending or Loan Arrangement"
                campaignInfo={campaignInfo}
                tooltip="Will this campaign include content related to direct lending or other loan arrangements?"
                error={error}
              />
            </Grid>
            <CampaignAttributeNew
              singleVal
              id="termsAndConditions"
              label="Terms & Conditions"
              tooltip="Indicates the campaign follows CTIA messaging principles and best practices"
              notes={termsAndConditions}
              campaignInfo={campaignInfo}
              error={error}
              handleChange={props.handleChange}
            />
          </Grid>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        data-testid="campaignDetailsFormOtherResponsibleParties"
      >
        <div className="campaign-dca wrapper">
          <div className="heading-block">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon
                  icon={faArrowProgress}
                  size="xl"
                  color="#49535D"
                />
              </Box>
              <h3 className="heading1">Other Responsible Parties</h3>
            </Box>
          </div>
          <Grid container>
            <Grid item xs={8}>
              <div className="description-block">
                For Sole Proprietor campaigns, if your CNP is not showing in the
                list, it means they are not enabled to receive Sole Proprietor
                campaigns. Please reach out to your CNP for more details.
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="campaignDetailsFormOtherResponsiblePartiesSelect"
              >
                <IntegrationReactSelect
                  options={cnpOptions}
                  label="Select your Connectivity Partner"
                  required={true}
                  error={error.upstreamCnpUid ? true : false}
                  value={cnpOptions.find(
                    (option) => option.value == campaignInfo.upstreamCnpUid
                  )}
                  handleChange={(selectedOption, keyName) =>
                    props.handleChange(selectedOption.value, keyName)
                  }
                  keyName="upstreamCnpUid"
                />
                <ToolTip
                  title="The “Connectivity Partner” is the Campaign Service Provider who you send messages to."
                  placement="right-end"
                />
                {error.upstreamCnpUid ? (
                  <h6 className="error-msg">{error.upstreamCnpUid}</h6>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="form-group-field"
                data-testid="campaignDetailsFormOtherResponsiblePartiesResellerSelect"
              >
                <IntegrationReactSelect
                  options={udpatedResellersOptions}
                  label="Select if reseller involved"
                  value={udpatedResellersOptions.find(
                    (option) => option.value == campaignInfo.resellerUid
                  )}
                  error={error.resellerUid ? true : false}
                  handleChange={(selectedOption, keyName) => {
                    if (selectedOption.value == 'new') {
                      props.handleAddReseller(true);
                    } else {
                      props.handleChange(selectedOption.value, keyName);
                    }
                  }}
                  keyName="resellerUid"
                />
                <ToolTip title="Select this option if you are registering a Campaign on behalf of a reseller or service provider." />
                {error.resellerUid ? (
                  <h6 className="error-msg">{error.resellerUid}</h6>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <UploadSizeExceededModal
        open={uploadSizeExceededOpen}
        onClose={() => {
          setUploadSizeExceededOpen(false);
        }}
      />
    </>
  );
}
