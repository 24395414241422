import { globalGetService } from '../utils/globalApiServices';

export function getArticle() {
  return globalGetService('csp/cms/article').then((response) => {
    if (response.ok) {
      return response.data;
    }
  });
}
export function getHelpAsides() {
  return globalGetService('csp/cms/helpAsides').then((response) => {
    if (response.ok) {
      return response.data;
    }
  });
}
export function getSimpleContent() {
  return globalGetService('csp/cms/simpleContent').then((response) => {
    if (response.ok) {
      return response.data;
    }
  });
}
export function getTermsAndConditions() {
  return globalGetService('csp/cms/termsAndConditions').then((response) => {
    if (response.ok) {
      return response.data;
    }
  });
}
