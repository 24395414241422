export const ROLES: { [key: string]: string } = {
  REVIEWER: 'ROLE_REVIEWER',
  USER: 'ROLE_USER',
  MANAGER: 'ROLE_MANAGER',
};

export const ROLE_DESCRIPTIONS: { [key: string]: string } = {
  [ROLES.REVIEWER]: 'View only access and cannot view Integrations information',
  [ROLES.USER]: 'View and edit access for all components',
  [ROLES.MANAGER]: 'View and edit access for all components and users',
};

export const MAX_EMAIL_COUNT = 5;
export const MAX_EMAIL_LENGTH = 100;
