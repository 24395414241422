import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons';

import Section from './Section';
import { booleanValueToText } from '../utils';

interface EnablementsSectionProps {
  platformFreeTrialEnabled?: boolean;
  ucaasEnabled?: boolean;
  soleProprietorEnabled?: boolean;
  dpaSigned?: boolean;
}

const Content = styled.div`
  display: grid;
  grid-template-columns: 237px 1fr 164px 1fr;
  row-gap: 12px;
  padding-top: 30px;
  border-top: 1px solid #cccfd0;
  font-family: Roboto, sans-serif;
`;

const Field = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const Value = styled.span`
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const EnablementsSection: FunctionComponent<EnablementsSectionProps> = ({
  platformFreeTrialEnabled,
  ucaasEnabled,
  soleProprietorEnabled,
  dpaSigned,
}) => {
  return (
    <Section
      icon={faCheckSquare}
      title="Enablements"
      testId="enablementsSection"
    >
      <Content>
        <Field>Platform Free Trial Enabled:</Field>
        <Value data-testid="platformFreeTrialEnabled">
          {booleanValueToText(platformFreeTrialEnabled)}
        </Value>

        <Field>UCaaS Enabled:</Field>
        <Value data-testid="ucaasEnabled">
          {booleanValueToText(ucaasEnabled)}
        </Value>

        <Field>Sole Proprietor Enabled:</Field>
        <Value data-testid="soleProprietorEnabled">
          {booleanValueToText(soleProprietorEnabled)}
        </Value>

        <Field>DPA Signed:</Field>
        <Value data-testid="dapSigned">{booleanValueToText(dpaSigned)}</Value>
      </Content>
    </Section>
  );
};

export default EnablementsSection;
